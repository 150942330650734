import ModalDireccion from '@/components/modales-despacho/ModalDireccion.vue';
<template>
  <div class="contenedor">
    <v-row class="pa-0 ma-0 justify-center">
      <v-col cols="12" sm="12" md="10" lg="8" class="pa-0 ma-0">
        <v-card class="mx-lg-7 ma-lg-5" elevation="1">
          <v-toolbar dark color="indigo darken-1" height="40">
            <router-link
              to="/agregar-contrato"
              v-if="this.$store.state.token_afiliacion_asesores != null"
              ><v-icon>mdi-arrow-left</v-icon></router-link
            >
            <v-spacer></v-spacer>
            <p class="mt-4" style="font-size: 15px">
              Información del responsable de pago - Titular
            </p>

            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text>
            <h4 class="titulo">Ingresa los siguientes datos:</h4>
            <v-row class="mt-3 pl-3 pr-3">
              <v-form
                class="d-flex"
                style="width: 100%"
                ref="formularioDatosBasicosTitular"
              >
                <v-row>
                  <!-- TIPO DE DOCUMENTO TITULAR-->
                  <v-col cols="12" sm="6" md="4" lg="4">
                    <label for="">Tipo de documento</label>
                    <v-select
                      :disabled="deshabilitar_campo"
                      v-if="!cargando_tipos_documento"
                      :items="tipos_documento"
                      item-text="tipodoc"
                      item-value="idTipoDoc"
                      v-model="titular.tipo_documento"
                      :menu-props="{ bottom: true, offsetY: true }"
                      outlined
                      dense
                      :rules="[(v) => !!v || 'Campo requerido']"
                    ></v-select>
                    <div v-else class="skeleton-loader"></div>
                  </v-col>

                  <!-- Alianzas -->
                  <!-- <v-col cols="12" sm="6" lg="6">
                  <label for="">Alianzas</label>
                  <v-select
                    :disabled="deshabilitar_campo"
                    :items="itemsAlianzas"
                    v-model="alianzaUsuario"
                    outlined
                    dense
            
                    :rules="[(v) => !!v || 'La alianza es requerida']"
                  ></v-select>
                </v-col> -->

                  <!-- NUMERO DE DOCUMENTO TITULAR -->
                  <v-col cols="12" sm="6" md="4" lg="4">
                    <label for="">Numero Documento</label>
                    <v-text-field
                      :disabled="deshabilitar_campo"
                      type="number"
                      v-model="titular.numero_documento"
                      :rules="[
                        (v) => !!v || 'Documento requerido',
                        (v) =>
                          v >= 100000 || 'Tiene que tener al menos 6 digitos',
                      ]"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <!-- TELEFONO CELULAR TITULAR -->
                  <v-col cols="12" sm="6" md="4" lg="4">
                    <label for="">Telefono Celular</label>
                    <v-text-field
                      type="number"
                      outlined
                      dense
                      v-model="titular.celular"
                      :disabled="deshabilitar_campo"
                      :rules="[
                        (v) => !!v || 'Telefono requerido',
                        (v) =>
                          v >= 1000000000 ||
                          'Tiene que tener al menos 10 digitos',
                      ]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>

              <!-- Boton continuar que se activa si el formulario es valido -->
              <v-col cols="12">
                <div class="d-flex justify-end">
                  <!-- <v-btn
                    v-if="this.$store.state.token_afiliacion_asesores != null"
                    class="success mr-5"
                    style="font-size: 11px"
                    :loading="validando_documento || guardando_datos_db"
                    :disabled="!validarFormularioUsuario"
                    @click="guardarDatosDb"
                    >Guardar Cambios</v-btn
                  > -->
                  <v-btn
                    class="primary"
                    style="font-size: 11px"
                    :loading="validando_documento"
                    :disabled="
                      !validarFormularioUsuario || boton_continuar == false
                    "
                    @click="activarModalInformativo('validacion-inicial')"
                    >Continuar</v-btn
                  >
                </div>
              </v-col>

              <!-- TABS -->
              <v-col cols="12" v-show="mostrarTabs">
                <v-tabs
                  v-model="tab"
                  centered
                  center-active
                  show-arrows
                  height="35"
                >
                  <v-tab ripple href="#tab-1" :disabled="habilitarPestaña1">
                    Datos del Titular
                  </v-tab>
                  <v-tab ripple href="#tab-2" :disabled="habilitarPestaña2">
                    Datos de Usuario
                  </v-tab>
                  <v-tab ripple href="#tab-3" :disabled="habilitarPestaña3">
                    Forma de Pago
                  </v-tab>

                  <v-tabs-items v-model="tab" touchless>
                    <!-- PESTAÑA 1 DATOS DEL TITULAR-->
                    <v-tab-item value="tab-1">
                      <FormularioTitular
                        :titular="titular"
                        :editar_titular="editar_titular"
                        :guardando_datos_titular="guardando_datos_titular"
                        @abrir-modal-asegurabilidad="abrirModalAsegurabilidad"
                        @activar-modal-informativo="activarModalInformativo"
                        @guardar-direccion-titular="guardarDireccionTitular"
                        @guardar-titular="guardarTitular"
                        @cancelar-editar-titular="cancelarEditarTitular"
                      />
                    </v-tab-item>

                    <!-- PESTAÑA 2 DATOS DEL BENEFICIARIO-->
                    <v-tab-item value="tab-2">
                      <FormularioBeneficiario
                        ref="formularioBeneficiario"
                        :beneficiario="beneficiario"
                        :beneficiarios="beneficiarios"
                        :editar_beneficiario="editar_beneficiario"
                        :codigo_autorizacion="codigo_autorizacion"
                        @activar-modal-no-posible-afiliacion="
                          activarModalNoPosibleAfiliacion
                        "
                        @cambiar-prioridad-beneficiario="
                          cambiarPrioridadBeneficiario
                        "
                        @cambiar-editando-beneficiario="
                          editar_beneficiario = false
                        "
                        @actualizar-beneficiario="actualizarBeneficiario"
                        @abrir-modal-asegurabilidad="abrirModalAsegurabilidad"
                        @activar-modal-informativo="activarModalInformativo"
                        @guardar-beneficiario="guardarBeneficiario"
                        @reiniciar-datos-beneficiario="
                          reiniciarDatosBeneficiario
                        "
                        @guardar-direccion-beneficiario="
                          guardarDireccionBeneficiario
                        "
                        @pago="pago"
                      />
                    </v-tab-item>

                    <!-- PESTAÑA 3 DATOS DE PAGO-->
                    <v-tab-item value="tab-3">
                      <FormularioPago
                        ref="formularioPago"
                        :guardando_datos_contrato="guardando_datos_contrato"
                        :titular_pago="titular.pago"
                        :cantidad_beneficiarios="beneficiarios.length"
                        :cantidad_titulares="titulares.length"
                        :puede_afiliarse="puede_afiliarse"
                        @cambio-periodo-pago="cambioPeriodoPago"
                        @abrir-modal-contrato="abrirModalContrato"
                        @cambio-debito-automatico="cambioDebitoAutomatico"
                      />
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- SIDECARD DATOS DEL PLAN -->
      <v-col cols="12" sm="12" md="10" lg="3" class="pa-0 ma-0">
        <SideCardDatosPlan
          :tab_actual="tab"
          :guardando_datos_contrato="guardando_datos_contrato"
          :puede_afiliarse="puede_afiliarse"
          :beneficiarios="beneficiarios"
          :medio_pago="titular.pago.medio_pago"
          :cantidad_meses="titular.pago.cantidad_meses_periodo_pago"
          :debito_automatico="titular.pago.debito_automatico"
          :editar_titular="editar_titular"
          :editar_beneficiario="editar_beneficiario"
          :texto_periodo_pago="titular.pago.periodo_pago_texto"
          @editar-titular="editarTitular"
          @editar-beneficiario="editarBeneficiario"
          @eliminar-beneficiario="eliminarBeneficiario"
          @cambio-descuento-afiliacion-web="cambioDescuentoAfiliacionWeb"
          :titulares="titulares"
        />
      </v-col>
    </v-row>

    <!-- MODALES -->

    <!-- MODAL COTIZAR AFILIACION -->
    <v-dialog v-model="modal_cotizar_afiliacion" width="370">
      <!-- Se condiciona el contenido del modal para que siempre se este renderizando la vista del modal y se ejecute el ciclo de vida created en el modal -->
      <ModalCotizarAfiliacion
        v-if="modal_cotizar_afiliacion"
        :data_cotizacion="data_cotizacion_prop"
        @continuar-afiliacion="continuarAfiliacion"
      />
    </v-dialog>
    <!-- FIN MODAL COTIZAR AFILIACION -->

    <!-- MODAL ASEGURABILIDAD -->
    <template v-if="modal_asegurabilidad_beneficiario">
      <v-dialog
        v-model="modal_asegurabilidad_beneficiario"
        persistent
        max-width="900px"
      >
        <ModalAsegurabilidad
          :tipo_de_usuario="tipo_de_usuario"
          :tratamientos="enfermedades_asegurabilidad"
          :tratamientos_seleccionados_prop="beneficiario.asegurabilidad"
          @cerrar-modal="modal_asegurabilidad_beneficiario = false"
          @aceptar-modal="obtenerAsegurabilidad"
        ></ModalAsegurabilidad>
      </v-dialog>
    </template>
    <!-- FIN MODAL ASEGURABILIDAD -->

    <!-- MODAL ASEGURABILIDAD -->
    <template v-if="modal_asegurabilidad_titular">
      <v-dialog
        v-model="modal_asegurabilidad_titular"
        persistent
        max-width="900px"
      >
        <ModalAsegurabilidad
          :tipo_de_usuario="tipo_de_usuario"
          :tratamientos="enfermedades_asegurabilidad"
          :tratamientos_seleccionados_prop="titular.asegurabilidad"
          @cerrar-modal="modal_asegurabilidad_titular = false"
          @aceptar-modal="obtenerAsegurabilidad"
        ></ModalAsegurabilidad>
      </v-dialog>
    </template>
    <!-- FIN MODAL ASEGURABILIDAD -->

    <!-- ALERT CONTRATO -->
    <!-- Este modal se abre cuando el usuario da click en completar la afiliación -->
    <template>
      <div class="text-center">
        <v-dialog v-model="modalContrato" width="1400">
          <ModalAceptarContrato @aceptar-contrato="aceptarContrato($event)" />
        </v-dialog>
      </div>
    </template>
    <!--FIN ALERT CONTRATO -->

    <!-- MODAL INFORMATIVO -->
    <!-- Este modal es dinamico, sirve para mostrar la mayoria de las alertas -->
    <v-dialog v-model="modalInformativo" persistent max-width="330px">
      <ModalInformativo
        @activar-modal-informativo="emisionModalInformativo"
        :titulo="tituloModalInformativo"
        :texto="textoModalInformativo"
        :emisor="emisor"
      />
    </v-dialog>

    <v-dialog v-model="modalMensajeError" persistent width="500px">
      <ModalMensajeError
        @modal-mensaje-error="activarMensajeError"
        :titulo="tituloError"
        :mensaje="mensajeError"
        :emisor="emisor"
        :codigo="codigo"
      />
    </v-dialog>

    <!-- Modales por refactorizar -->

    <v-dialog v-model="alertValidarEdadTitular" persistent max-width="400px">
      <v-card>
        <v-card-title>Titular</v-card-title>
        <v-card-text>
          Estimado usuario para ser contratante en Red Médica Vital debe ser
          mayor de edad.
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- MODAL CODIGO AUTORIZACION -->
    <v-dialog v-model="modal_codigo_autorizacion" persistent max-width="400px">
      <ModalCodigoAutorizacion
        v-if="modal_codigo_autorizacion"
        :telefono="titular.celular"
        :correo="correo_prop"
        @guardar-codigo-autorizacion="guardarCodigoAutorizacion"
        @cerrar-modal="modal_codigo_autorizacion = false"
      />
    </v-dialog>
    <!-- FIN MODAL COIDGO AUTORIZACION -->

    <!-- MODAL CODIGO CONFIRMACION SMS -->
    <v-dialog
      v-model="modal_codigo_confirmacion_sms"
      persistent
      max-width="500px"
    >
      <ModalConfirmarCodigoSms
        :telefono="titular.celular"
        :correo="correo_prop"
        @cerrar-modal="modal_codigo_confirmacion_sms = false"
        @cambiar-medio-confirmacion="cambiarMedioConfirmacion"
        @codigo-confirmado="modal_cotizar_afiliacion = true"
      />
    </v-dialog>
    <!-- FIN MODAL CODIGO CONFIRMACION SMS-->

    <div id="boxGateway"></div>
  </div>
</template>

<script>
import ModalAsegurabilidad from "@/components/modales-familiar/ModalAsegurabilidad";
import ModalAceptarContrato from "@/components/modales-familiar/ModalAceptarContrato";
import ModalPlanFamiliar from "@/components/modales-familiar/ModalPlanFamiliar";
import ModalInformativo from "@/components/modales-familiar/ModalInformativo";
import ModalMensajeError from "@/components/modales-familiar/ModalMensajeError";
import afiliacionService from "./services/afiliacionService.js";
import FormularioTitular from "./components/FormularioTitular.vue";
import SideCardDatosPlan from "./components/SideCardDatosPlan.vue";
import FormularioBeneficiario from "./components/FormularioBeneficiario.vue";
import FormularioPago from "./components/FormularioPago.vue";
import ModalCotizarAfiliacion from "./components/ModalCotizarAfiliacion.vue";
import beneficiarioService from "../beneficiarios/services/beneficiarioService";
import titularService from "../titulares/services/titularService";
import ModalCodigoAutorizacion from "./components/ModalCodigoAutorizacion.vue";
import ModalConfirmarCodigoSms from "./components/ModalConfirmarCodigoSms.vue";
import { Md5 } from "ts-md5";
import configsService from "../general/services/configsService";
export default {
  name: "Familiar",
  data: () => ({
   
    //------------- DATOS TITULAR ----------------
    titular: {
      id_grupo: null,
      id: null,
      tipo_documento: 1,
      numero_documento: null,
      celular: null,
      telefono: null,
      primer_nombre: "",
      segundo_nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
      nombre_completo: "",
      genero: null,
      fecha_nacimiento: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      estado_civil: null,
      profesion: null,
      estrato: null,
      direccion: null,
      direccion_completa: "",
      correo: "",
      regimen: null,
      eps: "",
      asegurabilidad: [],
      asegurabilidad_texto: [],
      asegurabilidad_observaciones: "",
      anexos:[],
      tarifa: 0,
      id_alianza:0,
      descuento_afiliacion_web: 0,
      total_pagado: 0,
      pago: {
        periodo_pago: 1,
        periodo_pago_texto: "mensual",
        cantidad_meses_periodo_pago: 1,
        medio_pago: 1,
        debito_automatico: false,
        periodo_debito: null,
        banco: null,
        sucursal: null,
        tipo_cuenta: null,
        numero_cuenta: null,
        tipo_documento_debito: null,
        nombre_titular_debito: "",
        numero_documento_debito: "",
        facturacion_fisica: false,
        facturacion_electronica: false,
      },
      es_beneficiario: false,
      estado_contrato: 0,
    },

    descuento_afiliacion_web: 0,
    descuento_aplica_sobre_cada_beneficiario: false,

    default_data_titular: {
      id_grupo: null,
      id: null,
      tipo_documento: 1,
      numero_documento: null,
      celular: null,
      telefono: null,
      primer_nombre: "",
      segundo_nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
      nombre_completo: "",
      genero: null,
      fecha_nacimiento: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      estado_civil: null,
      profesion: null,
      estrato: null,
      direccion: null,
      direccion_completa: "",
      correo: "",
      regimen: null,
      eps: "",
      asegurabilidad: [],
      asegurabilidad_texto: [],
      asegurabilidad_observaciones: "",
      anexos:[],
      tarifa: 0,
      id_alianza:0,
      descuento_afiliacion_web: 0,
      total_pagado: 0,
      pago: {
        periodo_pago: 1,
        periodo_pago_texto: "mensual",
        cantidad_meses_periodo_pago: 1,
        medio_pago: 1,
        debito_automatico: false,
        periodo_debito: null,
        banco: null,
        sucursal: null,
        tipo_cuenta: null,
        numero_cuenta: null,
        tipo_documento_debito: null,
        nombre_titular_debito: "",
        numero_documento_debito: "",
        facturacion_fisica: false,
        facturacion_electronica: false,
      },
      es_beneficiario: false,
      estado_contrato: 0,
    },

    editar_titular: false,

    //------------- DATOS BENEFICIARIO ----------------
    beneficiario: {
      id_grupo: null,
      id_titular: null,
      id: null,
      tipo_documento: 1,
      numero_documento: null,
      celular: null,
      telefono: null,
      primer_nombre: "",
      segundo_nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
      genero: null,
      fecha_nacimiento: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      estado_civil: null,
      profesion: null,
      estrato: null,
      direccion: null,
      direccion_completa: "",
      correo: "",
      regimen: null,
      parentesco: null,
      eps: "",
      asegurabilidad: [],
      asegurabilidad_texto: [],
      asegurabilidad_observaciones: "",
      anexos: [],
    },

    editar_beneficiario: false,
    prioridad_beneficiario: "",

    default_data_beneficiario: {
      id_grupo: null,
      id_titular: null,
      id: null,
      tipo_documento: 1,
      numero_documento: null,
      celular: null,
      telefono: null,
      primer_nombre: "",
      segundo_nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
      genero: null,
      fecha_nacimiento: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      estado_civil: null,
      profesion: null,
      estrato: null,
      direccion: null,
      direccion_completa: "",
      correo: "",
      regimen: null,
      parentesco: null,
      eps: "",
      asegurabilidad: [],
      asegurabilidad_texto: [],
      asegurabilidad_observaciones: "",
      anexos: [],
    },

    //------------ DATOS PAYU ------------------

    gateway: "",
    api_key: "",
    merchant_id_payu: "",
    account_id_payu: "",
    reference: "",
    descripcion_payu: "Afiliacion Red Medica.",
    total_payu: 0,
    iva_payu: 0,
    total_sin_iva_payu: 0,
    firma_payu: "",
    test: 1,

    //------------- INPUTS FORMULARIO --------------

    //Variables con seudo nombre "cargando" quieren decir que esta variables activan los skeleton-loaders de los campos  mientras se traen los datos de db

    guardando_datos_db: false,
    //Tipos de documento
    tipos_documento: [],
    cargando_tipos_documento: false,

    //Boton Continuar ubicado en los datos basicos del tiular (Tipo documento,numero documento,telefono)

    boton_continuar: true,
    validando_documento: false,

    //Variable que desactiva los en los datos basicos del tiular (Tipo documento,numero documento,telefono)
    deshabilitar_campo: false,

    //VARIABLES FORMULARIO TITULAR

    enfermedades_asegurabilidad: [],
    cargando_enfermedades_asegurabilidad: false,

    guardando_datos_contrato: false,
    guardando_datos_titular: false,

    //----------------- SIDE VAR DATOS AFILIACION------------------

    titulares: [],
    beneficiarios: [],

    modal_asegurabilidad_titular: false,

    modal_asegurabilidad_beneficiario: false,

    tipo_de_usuario: "",

    //----------- MODALES -----------------

    //Modal cotizar afiliacion
    modal_cotizar_afiliacion: false,
    //Variable que va contener todos los datos de la cotizacion en la vista para pasarselos al modal
    data_cotizacion_prop: {
      nombre_tipo_plan: "",
      id_plan: null,
      numero_personas: 1,
      nombre_periodo_pago: "",
      id_periodo_pago: null,
      cantidad_meses: null,
      nombre_medio_pago: "",
      id_medio_pago: null,
      debito_automatico: false,
      es_alianza: false,
      es_asesor: false,
    },

    //MODAL CODIGO AUTORIZACION
    modal_codigo_autorizacion: false,

    codigo_autorizacion: {
      id: null,
      id_tipo_codigo: null,
      confirmado: 0,
      descripcion: "",
    },

    codigos_autorizacion_usados: [],

    //Modal codigo confirmacion SMS

    modal_codigo_confirmacion_sms: false,
    correo_prop: "",

    // VARIABLES DE PESTAÑAS
    habilitarPestaña2: true,
    habilitarPestaña3: true,
    habilitarPestaña1: false,
    mostrarTabs: false,
    tab: "tab-1",
    tab_anterior: "tab-1",

    // Variables alerts y modales
    modalContrato: false,
    modalPlanFamiliar: false,
    modalAsegurabilidad: false,

    alertValidarEdadTitular: false, // Se activa si el titular no es mayor de edad

    //Variables Modal Informativo
    modalInformativo: false,
    tituloModalInformativo: "titulo",
    textoModalInformativo: "Texto del modal",
    emisor: "",

    // Variables Modal Mensaje de error
    modalMensajeError: false,
    tituloError: "Código",
    mensajeError:
      "El Código no es valido para este formato el error de código es",
    codigo: "234234",

    puede_afiliarse: false,
  }),
  created() {
    //SE TRAEN TODOS LOS TIPOS DE DOCUMENTOS
    this.cargando_tipos_documento = true;
    afiliacionService
      .getTiposDocumentos()
      .then((response) => {
        this.cargando_tipos_documento = false;
        this.tipos_documento = response.data;
      })
      .catch((error) => {
        this.$swal.fire({
          title: "Error al traer los tipos de documentos",
          icon: "error",
          position: "center",
          showConfirmButton: true,
          confirmButtonText: "OK",
        });
        console.log(error);
      });
  },
  // mounted(){
  //   this.redirigirPayu();
  // },

  computed: {
    /** Funcion que valida los datos basicos del titular (tipo_documento,numero_documento,celular) no esten vacios */
    validarFormularioUsuario() {
      if (
        this.titular.tipo_documento != null &&
        this.titular.numero_documento != null &&
        this.titular.celular != null
      ) {
        return true;
      }
    },
  },
  components: {
    ModalAsegurabilidad,
    ModalAceptarContrato,
    ModalPlanFamiliar,
    ModalInformativo,
    ModalMensajeError,
    FormularioTitular,
    SideCardDatosPlan,
    FormularioBeneficiario,
    FormularioPago,
    ModalCotizarAfiliacion,
    ModalCodigoAutorizacion,
    ModalConfirmarCodigoSms,
  },

  methods: {
    /**Funcion que valida el documento de titular */
    async validarDocumento() {
      //Se desactivan los campos de los datos basicos del titular (tipo_documento,numero_documento,celular)
      this.deshabilitar_campo = true;

      //Se inicia el estado de carga o de validacion de los datos
      this.validando_documento = true;

      this.titulares = [];
      this.beneficiarios = [];

      this.reiniciarDatosBeneficiario();

      //Se hace la peticion que valida si el documento existe y el estado de contrato es 8
      await afiliacionService
        .preContratoConsultado(this.titular.numero_documento)
        .then((response) => {
          if (response.data.length > 0) {
            this.tab_anterior = "tab-3";
            this.tab = "tab-3";
            this.validarPestanaAnterior();

            let datos_titular = response.data[0];

            //DATOS DEL FORMULARIO DEL TITULAR
            this.titular.id_grupo = datos_titular.ID_Grupo;
            this.titular.id = datos_titular.ID_Titular;
            this.titular.tipo_documento = datos_titular.TipoDoc;
            this.titular.celular = datos_titular.grucel;
            this.titular.telefono = datos_titular.grutel;
            this.titular.primer_nombre = datos_titular.tit_primer_nombre;
            this.titular.segundo_nombre = datos_titular.tit_segundo_nombre;
            this.titular.primer_apellido = datos_titular.tit_primer_apellido;
            this.titular.segundo_apellido = datos_titular.tit_segundo_apellido;
            this.titular.genero = datos_titular.id_sexo;
            this.titular.fecha_nacimiento = datos_titular.Fnacimiento;
            this.titular.estado_civil = datos_titular.EstCivil;
            this.titular.profesion = parseInt(datos_titular.Profesion);
            this.titular.estrato = datos_titular.Estrato;
            this.titular.direccion_completa = datos_titular.titdir;
            this.titular.direccion = datos_titular.direccion;
            this.titular.correo = datos_titular.mail;
            this.titular.regimen = datos_titular.tit_regimen;
            this.titular.eps = datos_titular.tit_eps;
            this.titular.asegurabilidad =
              datos_titular.enfermedades_asegurabilidad;
            this.titular.asegurabilidad_texto =
              datos_titular.enfermedades_asegurabilidad_texto;

            this.titular.tarifa = parseInt(datos_titular.Tarifa);

            //DATOS DEL FORMULARIO DE PAGO
            this.titular.pago.periodo_pago = parseInt(datos_titular.planDePago);
            this.titular.pago.medio_pago = parseInt(datos_titular.formaPago);
            this.titular.pago.periodo_debito = parseInt(
              datos_titular.debitoPeriodo
            );
            this.titular.pago.banco = parseInt(datos_titular.debitoBanco);
            this.titular.pago.sucursal = parseInt(
              datos_titular.debitoBancoSucursal
            );
            this.titular.pago.tipo_cuenta = parseInt(
              datos_titular.debitoBancoTipoCuenta
            );
            this.titular.pago.numero_cuenta = parseInt(
              datos_titular.debitoBancoNumCuenta
            );
            this.titular.pago.tipo_documento_debito = parseInt(
              datos_titular.debitoBancoTitularTipoDoc
            );
            this.titular.pago.numero_documento_debito = parseInt(
              datos_titular.debitoBancoTitularDoc
            );
            this.titular.pago.nombre_titular_debito =
              datos_titular.debitoBancoTitularCuenta;
            this.titular.pago.facturacion_electronica = parseInt(
              datos_titular.envfactelec
            );
            this.titular.pago.facturacion_fisica = parseInt(
              datos_titular.envfacfisica
            );

            //Se clona y agrega el titular ingresado https://midu.dev/como-clonar-un-array-en-javascript/
            this.titulares.push(JSON.parse(JSON.stringify(this.titular)));

            this.default_data_beneficiario.direccion = JSON.parse(
              JSON.stringify(this.titular.direccion)
            );
            this.default_data_beneficiario.direccion_completa = JSON.parse(
              JSON.stringify(this.titular.direccion_completa)
            );
            this.default_data_beneficiario.estrato = JSON.parse(
              JSON.stringify(this.titular.estrato)
            );

            this.cargarBeneficiarios(this.titular.id, this.titular.id_grupo);

            console.log(datos_titular);
          } else {
            this.reiniciarDatosTitular();
            this.tab_anterior = "tab-1";
            this.tab = "tab-1";
            this.validarPestanaAnterior();
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.puede_afiliarse =
        await this.validarCodigoAutorizacionValiadacionDocumento();

      if (this.puede_afiliarse) {
        //Se muestran los tabs
        this.mostrarTabs = true;

        // afiliacionService.registrarIntencion({
        //   documento: this.titular.numero_documento,
        //   celular: this.titular.celular,
        // });
      } else {
        this.activarModalNoPosibleAfiliacion(true);
        this.deshabilitar_campo = false;
        this.boton_continuar = true;
      }

      this.validando_documento = false;
    },

    /** Funcion que evalua si el titular se puede afiliar teniendo en cuenta el codigo de autorización
     *  @returns {boolean} validacion si se puede afiliar o no
     */
    async validarCodigoAutorizacionValiadacionDocumento() {
      let puede_afiliarse = true;

      //Si el codigo de autorizacion es diferente de 1 (Acceso total) en tabla codigos_autorizacion_tipos
      if (this.codigo_autorizacion.id_tipo_codigo != 1) {
        //Se validara si este se encuentra en la tabla beneficiarios de la db de medisoft
        await beneficiarioService
          .consultarBeneficiarioRmv(this.titular.numero_documento)
          .then((response) => {
            if (response.data.length > 0) {
              //En el caso de que se encuentre en la tabla de beneficiarios de medisoft

              //Se valida si tiene el codigo de autorizacion que salta la validacion de la blacklist en la tabla de beneficiarios
              //6 (Quitar lista Negra  Beneficiario) en tabla codigos_autorizacion_tipos
              if (this.codigo_autorizacion.id_tipo_codigo != 6) {
                //En el caso de no tener el codigo de autorizacion se validara si pertenece a la blacklist, en el caso de que pertenezca se le negara la afiliacion (campo blacklist, tabla beneficiarios medisoft)
                if (response.data[0].benblack == 1) {
                  puede_afiliarse = false;
                }
              }

              //Se valida tambien el estado en cartera del beneficiario con el campo bencba en el caso que deba algo
              // 6 (Falta de Pago) tabla tipo_bajas db medisoft
              // 13 (Deuda) tabla tipo_bajas db medisoft
              if (
                response.data[0].bencba == 6 ||
                response.data[0].bencba == 13
              ) {
                puede_afiliarse = false;
              }
            }
          });

        //Se validara si este se encuentra en la tabla titulares_afi de la db de medisoft
        await titularService
          .consultarTitularRmv(this.titular.numero_documento)
          .then(async (response) => {
            if (response.data.length > 0) {
              //En el caso de que se encuentre en la tabla de titulares_afi de medisoft

              //Se validara si no pertenece a la whitelist
              if (response.data[0].whitelist != 1) {
                //Se valida si pertenece a la blacklist se le niega el poder afiliarse
                if (response.data[0].blacklist == 1) {
                  puede_afiliarse = false;
                } else {
                  //Se consultara el estado en cartera de los beneficiarios relacionados con este titular
                  let beneficiarios_titular =
                    await beneficiarioService.consultarEstadoCarteraBenRmv(
                      response.data[0].ID_Titular
                    );

                  if (beneficiarios_titular.data.length > 0) {
                    beneficiarios_titular.data.forEach((beneficiario) => {
                      //Se valida tambien el estado en cartera de cada beneficiario con el campo bencba en el caso que deba algo
                      // 6 (Falta de Pago) tabla tipo_bajas db medisoft
                      // 13 (Deuda) tabla tipo_bajas db medisoft
                      if (
                        beneficiario.bencba == 6 ||
                        beneficiario.bencba == 13
                      ) {
                        puede_afiliarse = false;
                      }
                    });
                  }
                }
              }
            }
          });
      }

      return puede_afiliarse;
    },
    /**
     * Funcion que activa el mensaje de que no se puede realizar una afiliacion y puede abrir el modal codigo de autorización
     * @param {boolean} permite_codigo variable que identifica si se puede abrir el modal codigo de autorizacion
     */
    activarModalNoPosibleAfiliacion(permite_codigo) {
      this.$swal
        .fire({
          title:
            "No es posible completar el proceso de afiliación por favor contactar al 3114300 para más información.",
          icon: "info",
          position: "center",
          showConfirmButton: true,
          confirmButtonText: "OK",
        })
        .then(() => {
          if (permite_codigo) {
            this.modal_codigo_autorizacion = true;

            if (this.titulares.length > 0) {
              this.titulares[0].estado_contrato = 4;
            }
            this.titular.estado_contrato = 4;

            this.guardarDatosDb();
          }
        });
    },

    /**
     * Funcion que trae todos los beneficiarios que le pertenecen a un titular y a un grupo
     * @param {number} id_titular id del titular en la tabla contratos_pre_titulares_afi (ID_Titular)
     * @param {number} id_grupo id del titular en la tabla contratos_pre_grupos_afi (ID_Grupo)
     */
    async cargarBeneficiarios(id_titular, id_grupo) {
      await beneficiarioService
        .beneficiariosTitular(id_titular, id_grupo,'_temporal')
        .then((response) => {
          let beneficiarios = response.data;

          if (beneficiarios.length > 0) {
            let lista_beneficiarios = [];
            beneficiarios.forEach((beneficiario) => {
              this.beneficiario.id = beneficiario.ID_Ben;
              this.beneficiario.id_grupo = beneficiario.ID_Grupo;
              this.beneficiario.id_titular = beneficiario.ID_Titular;
              this.beneficiario.tipo_documento = beneficiario.TipoDoc;
              this.beneficiario.numero_documento = beneficiario.bendoc;
              this.beneficiario.celular = beneficiario.bencel;
              this.beneficiario.telefono = beneficiario.bentel;
              this.beneficiario.primer_nombre = beneficiario.bennm1;
              this.beneficiario.segundo_nombre = beneficiario.bennm2;
              this.beneficiario.primer_apellido = beneficiario.benap1;
              this.beneficiario.segundo_apellido = beneficiario.benap2;
              this.beneficiario.genero = beneficiario.id_sexo;
              this.beneficiario.fecha_nacimiento = beneficiario.bennac;
              this.beneficiario.estrato = parseInt(beneficiario.bentip);
              this.beneficiario.direccion = beneficiario.direccion;
              this.beneficiario.direccion_completa = beneficiario.bendir;
              this.beneficiario.correo = beneficiario.mail;
              this.beneficiario.regimen = beneficiario.regimen_eps;
              this.beneficiario.parentesco = beneficiario.Parentesco;
              this.beneficiario.eps = beneficiario.beneps;
              this.beneficiario.asegurabilidad =
                beneficiario.enfermedades_asegurabilidad;
              this.beneficiario.asegurabilidad_texto =
                beneficiario.enfermedades_asegurabilidad_texto;

              if (
                this.beneficiario.numero_documento ==
                this.titular.numero_documento
              ) {
                this.titular.es_beneficiario = true;

                this.titular.eps = this.beneficiario.eps;
                this.titular.regimen = this.beneficiario.regimen;
                this.titular.asegurabilidad =
                  this.beneficiario.enfermedades_asegurabilidad;
                this.titular.asegurabilidad_texto =
                  this.beneficiario.enfermedades_asegurabilidad_texto;

                lista_beneficiarios.push(this.beneficiario);
              } else {
                lista_beneficiarios.push(this.beneficiario);
              }
            });

            this.beneficiarios = lista_beneficiarios;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /**Funcion que agrega los datos del titular al side bar */
    async guardarTitular() {
      this.guardando_datos_titular = true;
      this.titulares = [];

      //Se clona y agrega el titular ingresado https://midu.dev/como-clonar-un-array-en-javascript/
      this.titulares.push(JSON.parse(JSON.stringify(this.titular)));

      if (this.titular.anexos.length > 0) {
        this.titulares[0].anexos = [
          ...this.titular.anexos,
        ];
      }

      this.default_data_beneficiario.direccion = JSON.parse(
        JSON.stringify(this.titular.direccion)
      );
      this.default_data_beneficiario.direccion_completa = JSON.parse(
        JSON.stringify(this.titular.direccion_completa)
      );
      this.default_data_beneficiario.estrato = JSON.parse(
        JSON.stringify(this.titular.estrato)
      );

      if (this.titular.es_beneficiario) {
        await this.getPrioridadBeneficiario(this.titular.numero_documento);

        if (
          this.prioridad_beneficiario == "4" ||
          this.prioridad_beneficiario == "5"
        ) {
          this.activarModalNoPosibleAfiliacion(false);
          this.guardando_datos_titular = false;
          return;
        }

        if (
          !this.titulares[0].direccion.con_covertura &&
          (this.codigo_autorizacion != 1 || this.codigo_autorizacion != 2)
        ) {
          this.activarModalNoPosibleAfiliacion(false);
          this.guardando_datos_titular = false;
          return;
        } else {
          this.reiniciarDatosBeneficiario();
          this.beneficiario.tipo_documento = this.titular.tipo_documento;
          this.beneficiario.numero_documento = this.titular.numero_documento;
          this.beneficiario.primer_nombre = this.titular.primer_nombre;
          this.beneficiario.segundo_nombre = this.titular.segundo_nombre;
          this.beneficiario.primer_apellido = this.titular.primer_apellido;
          this.beneficiario.segundo_apellido = this.titular.segundo_apellido;
          this.beneficiario.direccion = this.titular.direccion;
          this.beneficiario.direccion_completa =
            this.titular.direccion_completa;
          this.beneficiario.fecha_nacimiento = this.titular.fecha_nacimiento;
          this.beneficiario.genero = this.titular.genero;
          this.beneficiario.estrato = this.beneficiario.estrato;
          this.beneficiario.telefono = this.titular.telefono;
          this.beneficiario.celular = this.titular.celular;
          this.beneficiario.eps = this.titular.eps;
          this.beneficiario.regimen = this.titular.regimen;
          this.beneficiario.parentesco = 9;
          this.beneficiario.asegurabilidad = this.titular.asegurabilidad;
          this.beneficiario.asegurabilidad_texto =
            this.titular.asegurabilidad_texto;
          this.beneficiario.asegurabilidad_observaciones =
            this.titular.asegurabilidad_observaciones;

          this.beneficiarios.unshift(this.beneficiario);
          this.reiniciarDatosBeneficiario();
        }
      }

      if (!this.editar_titular) {
        //Se deshabilita la pestaña de los datos del titular
        this.habilitarPestaña1 = true;

        //Se habilita la pestaña de los datos del beneficiario
        this.habilitarPestaña2 = false;

        this.beneficiario.direccion = JSON.parse(
          JSON.stringify(this.titular.direccion)
        );
        this.beneficiario.direccion_completa = JSON.parse(
          JSON.stringify(this.titular.direccion_completa)
        );
        this.beneficiario.estrato = JSON.parse(
          JSON.stringify(this.titular.estrato)
        );

        //Se cambia al tab de los datos del beneficiario
        this.changeTab();
      } else {
        //En el caso de que se este editando

        //Se valida si no hay beneficiarios
        if (this.beneficiarios.length == 0) {
          //Se en el caso de que no hay se reinicia el formulario para agregar un nuevo beneficiario
          this.$refs.formularioBeneficiario.agregarOtroBeneficiario();

          //Se deshabilita la pestaña del titular
          this.habilitarPestaña1 = true;
          //Se habilita la pestaña del beneficiario
          this.habilitarPestaña2 = false;
          //Se deshabilita la pestaña del pago
          this.habilitarPestaña3 = true;

          //Se cambia a la ventana del beneficiario
          this.changeTab();
        } else {
          //Se valida cual fue la pestaña anterios
          this.validarPestanaAnterior();

          //Se cambia a la pestaña anterior
          this.tab = JSON.parse(JSON.stringify(this.tab_anterior));

          //Se desactiva el evento de editar titular
          this.editar_titular = false;
        }
      }
      this.guardando_datos_titular = false;
    },

    /**
     * Funcion que determina la prioridad de un beneficiario
     * @param {number} numero_documento numero de documento del beneficiario a consultar la prioridad
     */
    async getPrioridadBeneficiario(numero_documento) {
      await beneficiarioService
        .consultarTotalServicios(numero_documento)
        .then(async (response) => {
          if (response.data.length > 0) {
            let benTotalServicios = response.data;

            await beneficiarioService
              .consultarMesesBen(benTotalServicios[0].ID_Ben)
              .then((response) => {
                if (response.data.length > 0) {
                  let meses = response.data;
                  //PS
                  benTotalServicios[0].ps = (
                    (benTotalServicios[0].total_servicios * 100) /
                    (0.25 * meses.meses)
                  ).toFixed(1);
                  console.log(
                    benTotalServicios[0].total_servicios,
                    meses.meses
                  );
                  //debugger

                  let prioridad;
                  //Si tiene estado ApoyoVip 1 en la base de datos tendrá prioridad VIP
                  if (benTotalServicios[0].ApoyoVip == 1) {
                    prioridad = "VIP";
                  } else if (benTotalServicios[0].ID_Titular == 7477) {
                    //La asociación viva cerritos se toma como prioridad APE
                    prioridad = "APE";
                  } else if (benTotalServicios[0].bencba == 15) {
                    //Las cortesías tienen prioridad COR
                    prioridad = "COR";
                  } else {
                    //Si el beneficiario solo tiene un servicio y lleva menos de 6 meses
                    //Siempre será prioridad 2
                    if (
                      benTotalServicios[0].total_servicios == "1" &&
                      meses.meses <= 6
                    ) {
                      prioridad = "2";
                    } else {
                      //Dependiendo el promedio del contrato
                      //se determina la prioridad del servicio
                      if (
                        parseInt(benTotalServicios[0].ps) <
                        parseInt(this.prioridades[0].rango_final)
                      ) {
                        prioridad = this.prioridades[0].prioridad;
                      } else if (
                        parseInt(benTotalServicios[0].ps) <
                        parseInt(this.prioridades[1].rango_final)
                      ) {
                        prioridad = this.prioridades[1].prioridad;
                      } else if (
                        parseInt(benTotalServicios[0].ps) <
                        parseInt(this.prioridades[2].rango_final)
                      ) {
                        prioridad = this.prioridades[2].prioridad;
                      } else if (
                        parseInt(benTotalServicios[0].ps) <
                        parseInt(this.prioridades[3].rango_final)
                      ) {
                        prioridad = this.prioridades[3].prioridad;
                      } else if (
                        parseInt(benTotalServicios[0].ps) <
                        parseInt(this.prioridades[4].rango_final)
                      ) {
                        prioridad = this.prioridades[4].prioridad;
                      } else {
                        prioridad = "5";
                      }
                      //debugger
                    }
                  }
                  //Se asigna la prioridad en la variable global del modulo
                  this.prioridad_beneficiario = prioridad;
                }
              });
          }
        });
    },

    /**
     * Funcion que cambia la prioridad de un beneficiario desde un evento en un componente
     * @param {string} prioridad nueva prioridad a asignar
     */
    cambiarPrioridadBeneficiario(prioridad) {
      this.prioridad_beneficiario = prioridad;
    },

    /** Funcion que valida el evento del boton cancelar al momento de editar el titular */
    cancelarEditarTitular() {
      this.editar_titular = false;

      if (this.titular.es_beneficiario) {
        this.reiniciarDatosBeneficiario();
        this.beneficiario.tipo_documento = this.titular.tipo_documento;
        this.beneficiario.numero_documento = this.titular.numero_documento;
        this.beneficiario.primer_nombre = this.titular.primer_nombre;
        this.beneficiario.segundo_nombre = this.titular.segundo_nombre;
        this.beneficiario.primer_apellido = this.titular.primer_apellido;
        this.beneficiario.segundo_apellido = this.titular.segundo_apellido;
        this.beneficiario.direccion = this.titular.direccion;
        this.beneficiario.direccion_completa = this.titular.direccion_completa;
        this.beneficiario.fecha_nacimiento = this.titular.fecha_nacimiento;
        this.beneficiario.genero = this.titular.genero;
        this.beneficiario.estrato = this.beneficiario.estrato;
        this.beneficiario.telefono = this.titular.telefono;
        this.beneficiario.celular = this.titular.celular;
        this.beneficiario.eps = this.titular.eps;
        this.beneficiario.regimen = this.titular.regimen;
        this.beneficiario.parentesco = 9;
        this.beneficiario.asegurabilidad = this.titular.asegurabilidad;
        this.beneficiario.asegurabilidad_texto =
          this.titular.asegurabilidad_texto;
        this.beneficiario.asegurabilidad_observaciones =
          this.titular.asegurabilidad_observaciones;

        this.beneficiarios.unshift(this.beneficiario);
        this.reiniciarDatosBeneficiario();
      }

      //Se valida si hay beneficiarios
      if (this.beneficiarios.length > 0) {
        //Se valida cual fue la pestaña anterios
        this.validarPestanaAnterior();

        this.tab = JSON.parse(JSON.stringify(this.tab_anterior));
      } else {
        //Se emite la funcion de agregarOtroBeneficiario en el formulario del beneficiario
        this.$refs.formularioBeneficiario.agregarOtroBeneficiario();

        //Se deshabilita la pestaña del titular
        this.habilitarPestaña1 = true;
        //Se habilita la pestaña del beneficiario
        this.habilitarPestaña2 = false;
        //Se deshabilita la pestaña del pago
        this.habilitarPestaña3 = true;

        //Se cambia a la ventana del beneficiario
        this.changeTab();
      }
    },

    /**Funcion que asigna la direccion al titular que se esta modificando o creado
     * @param {object} direccion datos de la direccion generadas
     */
    guardarDireccionTitular(direccion) {
      this.titular.direccion_completa = JSON.parse(
        JSON.stringify(direccion.direccion_completa)
      );
      this.titular.direccion = JSON.parse(JSON.stringify(direccion));
    },

    /**Funcion que habilita el formulario del titular para poder editarlo*/
    editarTitular() {
      //Se guarda el tab en el que estaba al momento de dar editar titular
      this.tab_anterior = JSON.parse(JSON.stringify(this.tab));

      if (this.titulares.length > 0) {
        if (this.titulares[0].es_beneficiario) {
          this.beneficiarios.splice(0, 1);
        }

        let entradas = Object.entries(this.titulares[0]);

        entradas.forEach((entrada) => {
          this.titular[entrada[0]] = entrada[1];
        });
      }

      //Se habilita la pestaña del titular
      this.habilitarPestaña1 = false;
      //Se deshabilita la pestaña del beneficiario
      this.habilitarPestaña2 = true;
      //Se deshabilita la pestaña del pago
      this.habilitarPestaña3 = true;

      //Se activa la variable de editar titular
      this.editar_titular = true;

      //Se cambia a la primera pestaña
      this.tab = "tab-1";
    },

    /**Funcion que guarda los datos del titular al titular de la lista de titulares */
    guardarDatosPagoTitular() {
      if (this.titulares.length > 0) {
        this.titulares[0].pago = JSON.parse(JSON.stringify(this.titular.pago));
      }
    },

    /** Funcion que se encarga de reiniciar los datos del titular a default*/
    reiniciarDatosTitular() {
      let tipo_documento = JSON.parse(
        JSON.stringify(this.titular.tipo_documento)
      );
      let numero_documento = JSON.parse(
        JSON.stringify(this.titular.numero_documento)
      );
      let celular = JSON.parse(JSON.stringify(this.titular.celular));
      //Se le asigna simplemente los datos que tenia por default en la variable default_data_titular
      this.titular = JSON.parse(JSON.stringify(this.default_data_titular));

      this.titular.tipo_documento = tipo_documento;
      this.titular.numero_documento = numero_documento;
      this.titular.celular = celular;
    },

    /**Funcion que agrega un nuevo beneficiario a la lista de beneficiarios */
    guardarBeneficiario() {
      //Se clona y agrega el beneficiario ingresado https://midu.dev/como-clonar-un-array-en-javascript/
      this.beneficiarios.push(JSON.parse(JSON.stringify(this.beneficiario)));

      //Se clona los anexos de manera diferente porque el metodo anterior de cierta forma elimina los datos de los archivos al pasarlos a json  https://midu.dev/como-clonar-un-array-en-javascript/
      if (this.beneficiario.anexos.length > 0) {
        this.beneficiarios[this.beneficiarios.length - 1].anexos = [
          ...this.beneficiario.anexos,
        ];
      }
    },

    /**Funcion que asigna la direccion al titular que se esta modificando o creado
     * @param {object} direccion datos de la direccion generadas
     */
    guardarDireccionBeneficiario(direccion) {
      this.beneficiario.direccion_completa = JSON.parse(
        JSON.stringify(direccion.direccion_completa)
      );
      this.beneficiario.direccion = JSON.parse(JSON.stringify(direccion));
    },

    /**Funcion para busca y asigna el beneficiario a editar */
    editarBeneficiario(tipo_documento, numero_documento) {
      if (numero_documento == this.titular.numero_documento) {
        this.editarTitular();
        return;
      }

      //Se busca al beneficiario a editar y se asignan los datos a la variable del beneficiario
      let beneficiario_encontrado = null;
      if (this.beneficiarios.length > 0) {
        this.beneficiarios.forEach((beneficiario) => {
          if (
            beneficiario.tipo_documento == tipo_documento &&
            beneficiario.numero_documento == numero_documento
          ) {
            beneficiario_encontrado = beneficiario;
          }
        });
      }

      //Se valida que se haya encontrado al beneficiario
      if (beneficiario_encontrado != null) {
        //Se reinician los datos del formulario del beneficiario
        this.reiniciarDatosBeneficiario();

        //Se activa la variable de editar beneficiario
        this.editar_beneficiario = true;

        //Se obtiene las keys y sus valores con el metodo Object.entries https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
        let beneficiario_entradas = Object.entries(beneficiario_encontrado);

        //Se van asignando cada uno de los valores al formulario del beneficiario
        beneficiario_entradas.forEach((entrada) => {
          this.beneficiario[entrada[0]] = entrada[1];
        });

        //Se guarda el tab donde estaba antes de dar editar beneficiario
        this.tab_anterior = JSON.parse(JSON.stringify(this.tab));

        //Se deshabilita la pestaña del titular
        this.habilitarPestaña1 = true;
        //Se habilita la pestaña del beneficiario
        this.habilitarPestaña2 = false;
        //Se deshabilita la pestaña del pago
        this.habilitarPestaña3 = true;

        //Se cambia al tab del beneficiario
        this.tab = "tab-2";
      } else {
        this.$swal.fire({
          title: "Error al tratar de modificar el beneficiario",
          icon: "error",
          position: "center",
          showConfirmButton: true,
          confirmButtonText: "OK",
        });
      }
    },

    /**Funcion que actualiza los datos de un beneficairio modificado */
    actualizarBeneficiario() {
      if (this.beneficiarios.length > 0) {
        //Se crea una variable que va contener los beneficiarios con las modificaciones realizadas
        let nueva_lista_beneficiarios = [];
        this.beneficiarios.forEach((beneficiario) => {
          //Si el tipo y numero de documento coiniciden se agregan los nuevos datos modificados del beneficiario
          if (
            beneficiario.tipo_documento == this.beneficiario.tipo_documento &&
            beneficiario.numero_documento == this.beneficiario.numero_documento
          ) {
            nueva_lista_beneficiarios.push(this.beneficiario);
          } else {
            nueva_lista_beneficiarios.push(beneficiario);
          }
        });

        //Se asignan los beneficiarios
        this.beneficiarios = nueva_lista_beneficiarios;

        //Se desactiva editar beneficiario
        this.editar_beneficiario = false;

        this.validarPestanaAnterior();

        //Se cambia a la pestana anterior
        this.tab = JSON.parse(JSON.stringify(this.tab_anterior));
      } else {
        this.editar_beneficiario = false;
      }
    },

    /**Funcion que valida la pestaña anterior y habilita y deshabilita pestañas */
    validarPestanaAnterior() {
      if (this.tab_anterior == "tab-1") {
        //Se habilita la pestaña del titular
        this.habilitarPestaña1 = false;
        //Se deshabilita la pestaña del beneficiario
        this.habilitarPestaña2 = true;
        //Se deshabilita la pestaña del pago
        this.habilitarPestaña3 = true;
      } else if (this.tab_anterior == "tab-2") {
        //Se deshabilita la pestaña del titular
        this.habilitarPestaña1 = true;
        //Se habilita la pestaña del beneficiario
        this.habilitarPestaña2 = false;
        //Se deshabilita la pestaña del pago
        this.habilitarPestaña3 = true;
      } else {
        //Se deshabilita la pestaña del titular
        this.habilitarPestaña1 = true;
        //Se deshabilita la pestaña del beneficiario
        this.habilitarPestaña2 = true;
        //Se habilita la pestaña del pago
        this.habilitarPestaña3 = false;
      }
    },

    /**Funcion que elimina un beneficiario */
    eliminarBeneficiario(tipo_documento, numero_documento) {
      if (numero_documento == this.titular.numero_documento) {
        this.titular.es_beneficiario = false;
        this.titular.regimen = null;
        this.titular.eps = null;
        this.titular.asegurabilidad_texto = [];
        this.titular.asegurabilidad = [];

        this.titulares[0].es_beneficiario = false;
        this.titulares[0].regimen = null;
        this.titulares[0].eps = null;
        this.titulares[0].asegurabilidad_texto = [];
        this.titulares[0].asegurabilidad = [];
      }
      //Se crea una variable que va contener los beneficiarios sin el que se va eliminar
      let nueva_lista_beneficiarios = [];

      this.beneficiarios.forEach((beneficiario) => {
        //En el caso de que coincida el tipo de documento y el numero de documento no se agrega a la nueva lista de beneficiarios
        if (
          beneficiario.tipo_documento == tipo_documento &&
          beneficiario.numero_documento == numero_documento
        ) {
        } else {
          nueva_lista_beneficiarios.push(
            JSON.parse(JSON.stringify(beneficiario))
          );
        }
      });

      //Se asigna la nueva lista de beneficiarios
      this.beneficiarios = JSON.parse(
        JSON.stringify(nueva_lista_beneficiarios)
      );

      //En el caso de que se este en formulario del beneficiario se validan los datos del beneficiaroi eliminado
      if (this.tab == "tab-2") {
        this.$refs.formularioBeneficiario.validarUsuarioEliminado(
          tipo_documento,
          numero_documento
        );
        //En el caso de que se este en el formulario del pago
      } else if (this.tab == "tab-3") {
        //Se valida que si no hay mas beneficiarios lo redirija a la pestaña de agregar beneficiarios
        if (this.beneficiarios.length == 0) {
          //Se deshabilita la pestaña del titular
          this.habilitarPestaña1 = true;
          //Se habilita la pestaña del beneficiario
          this.habilitarPestaña2 = false;
          //Se deshabilita la pestaña del pago
          this.habilitarPestaña3 = true;

          //Se cambia a la pestaña del beneficicario
          this.changeTab();
        }
      }
    },

    /** Funcion que se encarga de reiniciar los datos del beneficiarios a default*/
    reiniciarDatosBeneficiario() {
      //Se le asigna simplemente los datos que tenia por default en la variable default_data_beneficiario
      this.beneficiario = JSON.parse(
        JSON.stringify(this.default_data_beneficiario)
      );
    },

    /**Funcion que asigna la cantidad de meses a la que cambio el titular */
    cambioPeriodoPago({ cantidad_meses, periodo_pago }) {
      this.titular.pago.cantidad_meses_periodo_pago = cantidad_meses;
      this.titular.pago.periodo_pago_texto = periodo_pago;
    },

    /**Funcion que cambia el el estado de la variable de debito automatico del titular */
    cambioDebitoAutomatico(debito_automatico) {
      this.titular.pago.debito_automatico = debito_automatico;
    },

    /**Funcion que guarda los datos de la tarifa del titular y activa el modal con los datos del contrato */
    continuarAfiliacion({valor_por_afiliado,id_alianza}) {
      this.titulares[0].tarifa = valor_por_afiliado;
      this.titulares[0].id_alianza = id_alianza;
      this.emisionModalInformativo("aceptar", "confirmar-guardar-contrato");
    },

    /**Funcion que cambia a la pestaña del formulario del beneficiario */
    changeTab() {
      this.tab = "tab-2";
      window.scrollTo(0, 520);
    },

    /**Funcion que cambia a la pestaña del formulario de pago */
    changeTab2() {
      this.tab = "tab-3";
      window.scrollTo(0, 520);
    },

    /**Funcion que cambia a la pestaña de pago y deshabilita las pestañas anteriores */
    pago() {
      //Se deshabilita la pestaña del titular
      this.habilitarPestaña1 = true;
      //Se deshabilita la pestaña del beneficiario
      this.habilitarPestaña2 = true;
      //Se habilita la pestaña del pago
      this.habilitarPestaña3 = false;

      //Se cambia a la pestaña de pago
      this.changeTab2();
    },

    /**Funcion que abre el modal contrato */
    abrirModalContrato() {
      this.modalContrato = true;
    },

    /**
     * Funcion que guarda el codigo de autorizacion ya validado
     * @param {Object} codigo_autorizacion datos del codigo de autorizacion validado
     */
    guardarCodigoAutorizacion(codigo_autorizacion) {
      //Se asigna el codigo de autorizacion
      this.codigo_autorizacion = JSON.parse(
        JSON.stringify(codigo_autorizacion)
      );

      if (this.codigos_autorizacion_usados.length > 0) {
        //Se valida si el codigo de autorizacion que ingreso ya habia sido ingresado para no guardarlo en la lista de codigos usados
        let ya_ingresado = false;

        this.codigos_autorizacion_usados.forEach((codigo_usado) => {
          if (codigo_usado.id == this.codigo_autorizacion.id) {
            ya_ingresado = true;
          }
        });

        //En el caso de que el id del codigo de autorizacion ingresado todavia no haya sido ingresado en la lista de los usados se agrega
        if (!ya_ingresado) {
          this.codigos_autorizacion_usados.push(
            JSON.stringify(codigo_autorizacion)
          );
        }
      } else {
        //En el caso de que no se haya guardado un codigo de autorizacion se agrega directamente el codigo a la lista de codigos usados
        this.codigos_autorizacion_usados.push(
          JSON.stringify(codigo_autorizacion)
        );
      }
    },

    /**
     * Funcion que activa el modal que da diferentes datos sobre validaciones del sistema
     * @param {string} emisor el tipo de informacion que se va mostrar
     */
    activarModalInformativo(emisor) {
      this.modalInformativo = true;
      this.emisor = emisor;
      switch (emisor) {
        case "validacion-inicial":
          if (!this.$refs.formularioDatosBasicosTitular.validate()) {
            this.modalInformativo = false;
            return;
          }
          this.tituloModalInformativo = "Validación";
          this.textoModalInformativo =
            "Estimado usuario Red Medica Vital validará después de su afiliación su estado en el sistema general de seguridad social, encaso de presentar inconsistencias con su estado en dicho sistema será contactado por uno de nuestros representantes para realizarlas validaciones respectivas. De acuerdo al articulo 20, deldecreto 806 de 1998, solo podrán ser contratantes y/obeneficiarios/usuarios aquellas personas que estén afiliadas alrégimen contributivo del sistema general de seguridad social";
          this.boton_continuar = false;

          break;
        case "confirmar-contrato":
          this.tituloModalInformativo = "Confirmación";
          this.textoModalInformativo =
            " Certifico que la información registrada en este formulario es veraz.";
          break;
        case "confirmar-guardar-contrato":
          this.tituloModalInformativo = "Guardar Contrato";
          this.textoModalInformativo =
            "¿Está seguro que desea enviar el contrato?";
          break;
        case "validar-eps-titular":
          this.tituloModalInformativo = "Usuario";
          this.textoModalInformativo =
            "  Estimado usuario de acuerdo al artículo 20, del decreto 806 de 1998, solo podrán ser contratante/Usuario aquellas personas que estén afiliados al régimen contributivo del sistema general de seguridad social. Por ello Red Medica Vital verificará después de su afiliación su estado en el sistema general de seguridad social, de llegarse a presentar alguna inconsistencias con su estado en dicho sistema será contactado por uno de nuestros representantes para realizar las validaciones correspondientes.";
          break;
        case "validar-eps-beneficiario":
          this.tituloModalInformativo = "Usuario";
          this.textoModalInformativo =
            "  Estimado usuario de acuerdo al artículo 20, del decreto 806 de 1998, solo podrán ser contratante/Usuario aquellas personas que estén afiliados al régimen contributivo del sistema general de seguridad social. Por ello Red Medica Vital verificará después de su afiliación su estado en el sistema general de seguridad social, de llegarse a presentar alguna inconsistencias con su estado en dicho sistema será contactado por uno de nuestros representantes para realizar las validaciones correspondientes.";
          break;
        case "validar-regimen-titular":
          this.tituloModalInformativo = "Validación";
          this.textoModalInformativo =
            "Estimado usuario, no es posible realizar la afiliación de acuerdo al artículo 20 del decreto 806 de 1998; por el cual, el régimen de su EPS no es permitido para afiliarse a Red Medica Vital.";
          break;
        case "validar-regimen-beneficiario":
          this.tituloModalInformativo = "Validación";
          this.textoModalInformativo =
            "Estimado usuario, no es posible realizar la afiliación de acuerdo al artículo 20 del decreto 806 de 1998; por el cual, el régimen de su EPS no es permitido para afiliarse a Red Medica Vital.";
          break;

        default:
          break;
      }
    },
    /**Funcion que recibe el evento de aceptar o cancelar del modal informativo
     * @param {string} accion accion que se ejecuto ( aceptar o eliminar)
     * @param {string} emisor tipo de informacion que se mostro en el modal informativo
     */
    emisionModalInformativo(accion, emisor) {
      switch (emisor) {
        case "validacion-inicial":
          if (accion === "cancelar") {
            this.modalInformativo = false;
            this.boton_continuar = true;
          } else if (accion === "aceptar") {
            this.modalInformativo = false;
            this.validarDocumento();
          }

          break;
        case "confirmar-contrato":
          if (accion === "cancelar") {
            this.modalInformativo = false;
          } else if (accion === "aceptar") {
            this.modalInformativo = false;
            if (this.titulares.length > 0) {
              this.guardarDatosPagoTitular();

              this.data_cotizacion_prop.nombre_tipo_plan =
                this.$route.params.id_plan == 1
                  ? "Familiar"
                  : "Convenio Colectivo";
              this.data_cotizacion_prop.id_plan =
                this.$route.params.id_plan == 1 ? 1 : 2;
              this.data_cotizacion_prop.numero_personas =
                this.beneficiarios.length;
              this.data_cotizacion_prop.nombre_periodo_pago =
                this.titulares[0].pago.periodo_pago_texto;
              this.data_cotizacion_prop.id_periodo_pago =
                this.titulares[0].pago.periodo_pago;
              this.data_cotizacion_prop.cantidad_meses =
                this.titulares[0].pago.cantidad_meses_periodo_pago;
              this.data_cotizacion_prop.id_medio_pago =
                this.titulares[0].pago.medio_pago;
              this.data_cotizacion_prop.debito_automatico =
                this.titulares[0].pago.debito_automatico;
              this.data_cotizacion_prop.es_asesor =
                this.$store.state.roles_afiliacion_asesores.includes("asesor")
                  ? 1
                  : 0;

              this.abrirModalConfimarSms();
            }
          }

          break;
        case "confirmar-guardar-contrato":
          if (accion === "cancelar") {
            this.modalInformativo = false;
          } else if (accion === "aceptar") {
            this.modalInformativo = false;
            this.modal_cotizar_afiliacion = false;
            this.guardarDatosContrato();
          }

          break;
        case "validar-eps-titular":
          if (accion === "cancelar") {
            this.modalInformativo = false;
            this.titular.eps = null;
          } else if (accion === "aceptar") {
            this.modalInformativo = false;
          }
          break;
        case "validar-eps-beneficiario":
          if (accion === "cancelar") {
            this.modalInformativo = false;
            this.beneficiario.eps = null;
          } else if (accion === "aceptar") {
            this.modalInformativo = false;
          }
          break;
        case "validar-regimen-titular":
          if (accion === "cancelar") {
            this.modalInformativo = false;
            this.titular.regimen = 1;
          } else if (accion === "aceptar") {
            this.modalInformativo = false;
          }
          break;
        case "validar-regimen-beneficiario":
          if (accion === "cancelar") {
            this.modalInformativo = false;
            this.beneficiario.regimen = 1;
          } else if (accion === "aceptar") {
            this.modalInformativo = false;
          }
          break;
        default:
          this.modalInformativo = false;
          break;
      }
    },

    // Funcion que activa el modal con codigo de error
    activarMensajeError(accion) {
      if (accion == "cancelar") {
        this.modalMensajeError = false;
      } else if (accion == "aceptar") {
        this.modalMensajeError = false;
      }
    },

    // Función que se ejecuta cuando se acepta le contrato.
    aceptarContrato(evento) {
      if (evento === "cancelar") {
        this.modalContrato = false;
      } else if (evento === "aceptar") {
        this.modalContrato = false;
        this.mostrarTabs = true;
        this.deshabilitar_campo = true;
        this.activarModalInformativo("confirmar-contrato");
      }
    },

    /**Funcion que abre el modal para confirmar el codigo de sms o el codigo en el correo */
    abrirModalConfimarSms() {
      //Se asigna el valor del correo
      this.correo_prop = this.titulares[0].correo;
      this.modal_codigo_confirmacion_sms = true;
    },

    /**Funcion que modifica el telefono o correo desde el modal confirmar codigo sms
     * @param {string} medio_confirmacion determina si va ser el telefono o correo a modificar
     * @param {number} celular_modificado numero de telefono modificado
     * @param {string} correo_modificado correo modificado
     */
    cambiarMedioConfirmacion({
      medio_confirmacion,
      celular_modificado,
      correo_modificado,
    }) {
      if (medio_confirmacion == "telefono") {
        if (this.titulares.length > 0) {
          this.titulares[0].celular = celular_modificado;
        }
        this.titular.celular = celular_modificado;
      } else {
        this.correo_prop = correo_modificado;
        if (this.titulares.length > 0) {
          this.titulares[0].correo = correo_modificado;
        }
        this.titular.correo = correo_modificado;
      }
    },

    cambioDescuentoAfiliacionWeb({
      descuento_afiliacion_web,
      aplica_sobre_cada_beneficiario,
    }) {
      this.descuento_afiliacion_web = descuento_afiliacion_web;
      this.descuento_aplica_sobre_cada_beneficiario =
        aplica_sobre_cada_beneficiario;
    },
    /**Funcion que guarda todos los datos del contrato */
    async guardarDatosContrato() {
      this.guardando_datos_contrato = true;
      if (this.titulares.length > 0 && this.beneficiarios.length > 0) {
        this.titulares[0].estado_contrato = 1;
        await this.guardarDatosDb();
        await this.redirigirPasarelaPago();
      }
      this.guardando_datos_contrato = false;
    },

    async redirigirPasarelaPago() {
      this.titulares[0].nombre_completo =
        this.titulares[0].primer_nombre +
        " " +
        this.titulares[0].segundo_nombre +
        " " +
        this.titulares[0].primer_apellido +
        " " +
        this.titulares[0].segundo_apellido;

      this.total_payu =
        this.titulares[0].tarifa * this.beneficiarios.length -
        (this.descuento_aplica_sobre_cada_beneficiario
          ? this.descuento_afiliacion_web * this.beneficiarios.length
          : this.descuento_afiliacion_web);

      let formulario =
        `
        <form id="formularioGateway" action="`+ process.env.VUE_APP_API_PAGO_URL +`" method="post" hidden>
          <input type="hidden" name="api_key" value="` +
        process.env.VUE_APP_API_PAGO_KEY +
        `">
        <input type="hidden" name="id_grupo" value="` +
        this.titulares[0].id_grupo +
        `">
          <input type="hidden" name="numero_documento" value="` +
        this.titulares[0].numero_documento +
        `">
          <input type="hidden" name="nombre_completo" value="` +
        this.titulares[0].nombre_completo +
        `">
          <input type="hidden" name="correo" value="` +
        this.titulares[0].correo +
        `">
        <input type="hidden" name="test" value="1">
          <input type="hidden" name="iva" value="0">
          <input type="hidden" name="total_sin_iva" value="0">
          <input type="hidden" name="total" value="` +
        this.total_payu +
        `">
        </form>
    `;

      var boxGateway = document.getElementById("boxGateway");

      boxGateway.innerHTML = formulario;

      var form = document.getElementById("formularioGateway");

      await form.submit();
    },
    /**Funcion que guarda todos los datos del contrato en la db */
    async guardarDatosDb() {
      this.guardando_datos_db = true;
      let titular =
        this.titulares.length > 0 ? this.titulares[0] : this.titular;

      titular.descuento_afiliacion_web = this
        .descuento_aplica_sobre_cada_beneficiario
        ? this.descuento_afiliacion_web * this.beneficiarios.length
        : this.descuento_afiliacion_web;

      titular.total_pagar =
        titular.tarifa * this.beneficiarios.length -
        (this.descuento_aplica_sobre_cada_beneficiario
          ? this.descuento_afiliacion_web * this.beneficiarios.length
          : this.descuento_afiliacion_web);

      //Se guardan los datos tanto del titular como del beneficiario y el titular
      await afiliacionService
        .guardarDatosContrato(titular, this.beneficiarios, 0)
        .then((response) => {
          if (response.data.estatus == 1) {
            this.titular.id_grupo = parseInt(response.data.id_grupo);
            this.titular.id = parseInt(response.data.id_titular);

            if (this.titulares.length > 0) {
              this.titulares[0].id_grupo = parseInt(response.data.id_grupo);
              this.titulares[0].id = parseInt(response.data.id_titular);
            }

            if (this.beneficiarios.length > 0) {
              for (let i = 0; i < this.beneficiarios.length; i++) {
                this.beneficiarios[i].id_grupo = parseInt(
                  response.data.id_grupo
                );
                this.beneficiarios[i].id_titular = parseInt(
                  response.data.id_titular
                );
                this.beneficiarios[i].id = parseInt(
                  response.data.ids_beneficiarios[i]
                );
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire({
            title: "Error al guardar los datos del contrato",
            icon: "info",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
        });

      console.log(this.titulares);
      console.log(this.beneficiarios);

      if (this.titulares[0].anexos.length > 0) {
        //Se crea un nuevo obteto tipo FormData para poder guardar los archivos ya que no se pueden enviar directamente en los objetos de los benefiarios
        var formData = new FormData();

        formData.append("id_grupo", this.titulares[0].id_grupo);
        formData.append("id_titular", this.titulares[0].id);
        formData.append(
          "documento_titular",
          this.titulares[0].numero_documento
        );

        //Se envia la cantidad de anexos existentes
        formData.append("cantidad_anexos", this.titulares[0].anexos.length);

        //Mediante un ciclo for se van a agregando los anexos del beneficiario al formdata con el nombre file_numeroIndex
        for (let index = 0; index < this.titulares[0].anexos.length; index++) {
          formData.append("file_" + index, this.titulares[0].anexos[index]);
        }

        //Se envian los datos de los
        await afiliacionService
          .guardarAnexosTitular(formData)
          .then((response) => {
            console.log(response.data);
          });
      }

      //Se itera sobre todos los beneficiciarios para determinar si cualquiera de estos tiene anexos
      this.beneficiarios.forEach(async (beneficiario) => {
        if (beneficiario.anexos.length > 0) {
          //Se crea un nuevo obteto tipo FormData para poder guardar los archivos ya que no se pueden enviar directamente en los objetos de los benefiarios
          var formData = new FormData();

          formData.append("id_grupo", this.titulares[0].id_grupo);
          formData.append("id_beneficiario", beneficiario.id);
          formData.append(
            "documento_beneficiario",
            beneficiario.numero_documento
          );

          //Se envia la cantidad de anexos existentes
          formData.append("cantidad_anexos", beneficiario.anexos.length);

          if (beneficiario.anexos.length > 0) {
            //Mediante un ciclo for se van a agregando los anexos del beneficiario al formdata con el nombre file_numeroIndex
            for (let index = 0; index < beneficiario.anexos.length; index++) {
              formData.append("file_" + index, beneficiario.anexos[index]);
            }
          }

          //Se envian los datos de los
          await afiliacionService
            .guardarAnexosBeneficiario(formData)
            .then((response) => {
              console.log(response.data);
            });
        }
      });

      this.guardando_datos_db = false;
    },
    // Funciones que se ejecutan con los modales
    abrirModalAsegurabilidad(usuario, enfermedades_asegurabilidad) {
      this.enfermedades_asegurabilidad = enfermedades_asegurabilidad;
      this.tipo_de_usuario = usuario;
      if (usuario == "titular") {
        this.modal_asegurabilidad_titular = true;
      } else {
        this.modal_asegurabilidad_beneficiario = true;
      }
    },

    /**
     * Funcion que guarda todos los datos de las enfermedades de asegurabilidad seleccionadas por el usuario
     * @param {Array} tratamientos los id de los tratamientos selecionados
     * @param {string} observaciones las observaciones de los tratamientos seleccionados
     * @param {string} tipoUsuario usuario al que se le van a guardar los datos (titular o beneficiario)
     * @param {Array} tratamientos_texto los titulos de los tratamientos selecionados ya que son los que se van a mostrar en el input
     */
    obtenerAsegurabilidad(
      tratamientos,
      observaciones,
      tipoUsuario,
      tratamientos_texto
    ) {
      if (tipoUsuario == "titular") {
        this.titular.asegurabilidad = tratamientos;
        this.titular.asegurabilidad_texto = tratamientos_texto;
        this.titular.asegurabilidad_observaciones = observaciones;
        this.modal_asegurabilidad_titular = false;
      } else {
        this.beneficiario.asegurabilidad = tratamientos;
        this.beneficiario.asegurabilidad_texto = tratamientos_texto;
        this.beneficiario.asegurabilidad_observaciones = observaciones;
        this.modal_asegurabilidad_beneficiario = false;
      }
    },
  },
};
</script>

<style scoped>
.skeleton-loader {
  border-radius: 3px;
  width: 100%;
  height: 39px;
  display: block;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}
.texto {
  color: #000;
  font-size: 1.2em;
}
.titulo {
  color: #000;
  text-align: justify;
  font-size: 19px;
  font-weight: 600;
}
.card {
  padding: 8px;
  border: 1px solid rgb(221, 220, 220);
  border-radius: 8px;
}
.parrafos {
  font-weight: 400;
  font-size: 16px;
}

.v-tab {
  font-size: 10px;
  font-weight: 400;
  color: #000 !important;
}

.contenedor {
  width: 100% !important;
}
</style>
