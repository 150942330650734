//Se importa la instancia de axios
import apiClient from "../../general/services/index.js";

//Ruta a la que se va acceder de la api
const relative_path = "/codigo-autorizacion/";

export default {

  validarCodigoAutorizacion(codigo_autorizacion) {
    return apiClient.get(
      relative_path + "validar-codigo-autorizacion/"+
      codigo_autorizacion
    );
  },

};
