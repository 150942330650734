//Se importa la instancia de axios
import apiClient from "../../general/services/index.js";

//Ruta a la que se va acceder de la api
const relative_path = "/titular/";

export default {
  /**
   * Funcion que consulta si un numero de documento pertenenece a un titular en la tabla titutalares_afi de la db medisoft
   * @param {number} numero_documento numero de documento a consultar
   * @returns {Array<Object>} titular al que le pertencence el documento
   */
  consultarTitularRmv(numero_documento) {
    return apiClient.get(
      relative_path + "consultar-titular-rmv/" + numero_documento
    );
  },
};
