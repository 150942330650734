<template>
      <v-card>
            <v-card-title class="text-h5 justify-center">
               {{ titulo}}
            </v-card-title>

            <v-card-text >
                {{ texto }}
            </v-card-text>
               
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                dark
                @click="$emit('activar-modal-informativo', 'cancelar', emisor)"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="primary"
                dark
                @click="$emit('activar-modal-informativo', 'aceptar', emisor)">
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
</template>

<script>
export default {
    props: ['titulo', 'texto', 'emisor'],
    data: () => ({

    })
}
</script>

<style>

</style>