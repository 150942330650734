import axios from "axios";

// Creamos la base de las peticiones, con los datos necesarios
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_MAIL_APP_BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "api-token": process.env.VUE_APP_API_MAIL_TOKEN,
  },
});

const relative_path = "/afiliacion";

export default {
  generateCode(email) {
    return apiClient.post(relative_path + "/generar-codigo", email);
  },
  validateCode(code,email) {
    return apiClient.post(relative_path + "/validar-codigo", {codigo:code, correo:email});
  },
};
