<template>
  <v-card>
    <v-toolbar dark height="40" color="indigo darken-1">
      <v-spacer></v-spacer>
      <v-toolbar-title>Codigo Autorizacion</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text>
      <v-row justify-center>
        <v-col cols="12" class="mt-4">
          <label>Ingrese un codigo de autorizacion</label>
          <v-text-field
            outlined
            dense
            v-model="codigo_autorizacion"
            :disabled="validando_codigo_autorizacion"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="justify-center align-center mt-n5">
        <v-col cols="8" >
            <v-btn
           
            color="red"
            class="white--text"
            :disabled="validando_codigo_autorizacion"
            @click="$emit('cerrar-modal')"
            >Cancelar</v-btn
          >
          <v-btn
          class="ml-2"
            color="primary"
            :disabled="!validarBotonValidar"
            :loading="validando_codigo_autorizacion"
            @click="validarCodigoAutorizacion()"
            >Validar</v-btn
          >
         
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import codigoAutorizacionService from "../services/codigoAutorizacionService";
export default {
  data: () => ({
    validando_codigo_autorizacion: false,
    codigo_autorizacion: "",
  }),
  computed: {
    validarBotonValidar() {
      if (this.codigo_autorizacion.trim() != "") {
        return true;
      }
    },
  },
  methods: {
    async validarCodigoAutorizacion() {
      if (this.codigo_autorizacion.trim() != "") {
        this.validando_codigo_autorizacion = true;
        await codigoAutorizacionService
          .validarCodigoAutorizacion(this.codigo_autorizacion )
          .then((response) => {
            if (response.data.length > 0) {
              if (response.data[0].confirmado == 0) {
                this.$swal
                  .fire({
                    title: "Codigo Validado Correctamente:",
                    text: response.data[0].descripcion,
                    icon: "success",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    allowEnterKey: false,
                    position: "center",
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                  })
                  .then(() => {
                    this.$emit("guardar-codigo-autorizacion", response.data[0]);
                    this.$emit("cerrar-modal");
                  });
              } else {
                this.$swal.fire({
                  title: "Este codigo ya fue usado por otro usuario",
                  icon: "info",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  allowEnterKey: false,
                  timer: 2000,
                  position: "center",
                  showConfirmButton: false,
                  confirmButtonText: "OK",
                });
              }
            } else {
              this.$swal.fire({
                title: "Este codigo no existe",
                icon: "info",
                allowEscapeKey: false,
                allowOutsideClick: false,
                allowEnterKey: false,
                timer: 2000,
                position: "center",
                showConfirmButton: false,
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal.fire({
              title: "Error al validar el codigo de autorizacion",
              icon: "info",
              allowEscapeKey: false,
              allowOutsideClick: false,
              allowEnterKey: false,
              position: "center",
              showConfirmButton: true,
              confirmButtonText: "OK",
            });
          });

        this.validando_codigo_autorizacion = false;
      }
    },
  },
};
</script>
<style lang=""></style>
