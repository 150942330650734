<template>
  <v-card flat style="text-align: center">
    <p class="texto mt-4" style="font-weight: 500; font-size: 18px">
      Forma de Pago
    </p>
    <v-form ref="formularioPago">
      <v-row>
        <!-- PERIODO DE PAGO -->
        <v-col>
          <label style="font-weight: 600; font-size: 14px"
            >Periodo de Pago</label
          >
          <v-select
            v-if="!cargando_periodos_pago"
            outlined
            dense
            :items="periodos_pago"
            item-text="texto_periodo_pago"
            item-value="id"
            @change="cambioPeriodoPago"
            v-model="titular_pago.periodo_pago"
            :rules="[(v) => !!v || 'Campo Requerido']"
          ></v-select>
          <div v-else class="skeleton-loader mb-2" clas></div>
        </v-col>
        <!-- FIN PERIODO PAGO -->
      </v-row>

      <p class="mt-2" style="font-weight: 600; font-size: 14px">Medio</p>
      <p class="parrafos">
        Elija el medio de pago por el cual desea seguir cancelando su factura.
      </p>
      <p class="parrafos">
        <b>Nota:</b> El primer pago se puede realizar por tarjeta crédito o
        tarjeta debito, PSE, Efecty, Baloto.
      </p>

      <!-- MEDIOS DE PAGO -->
      <v-col>
        <v-select
          v-if="!cargando_medios_pago"
          :items="medios_pago"
          item-text="v"
          item-value="id_medio_pago"
          v-model="titular_pago.medio_pago"
          :rules="[(v) => !!v || 'Campo Requerido']"
          outlined
          dense
        ></v-select>
        <div v-else class="skeleton-loader"></div>
      </v-col>
      <!-- FIN MEDIOS DE PAGO -->

      <!-- SE ACTIVA CUANDO SE SELECCIONA MEDIO DE PAGO DEBITO AUTOMATICO -->
      <v-row v-if="debito_automatico">
        <!-- PERIODO DE DEBITO -->
        <v-col cols="12" sm="3">
          <label for=""
            >Periodo Débito<span class="campo_obligatorio">*</span></label
          >
          <v-select
            v-if="!cargando_periodos_debito"
            outlined
            :items="periodos_debito"
            :menu-props="{ bottom: true, offsetY: true }"
            item-text="periodo_debito"
            item-value="id"
            v-model="titular_pago.periodo_debito"
            :rules="[(v) => !!v || 'Campo Requerido']"
            dense
          ></v-select>
          <div v-else class="skeleton-loader"></div>
        </v-col>
        <!-- FIN PERIODO DE DEBITO -->

        <!-- BANCO  -->
        <v-col cols="12" sm="3">
          <label for="">Banco</label>
          <v-autocomplete
            v-if="!cargando_bancos"
            outlined
            dense
            :menu-props="{ bottom: true, offsetY: true }"
            :rules="[(v) => !!v || 'Campo Requerido']"
            item-text="nombre"
            item-value="id"
            v-model="titular_pago.banco"
            :items="bancos"
          ></v-autocomplete>
          <div v-else class="skeleton-loader"></div>
        </v-col>
        <!-- FIN BANCO -->

        <!-- SUCURSAL -->
        <v-col cols="12" sm="3">
          <label for="">Sucursal</label>
          <v-select
            outlined
            v-if="!cargando_sucursales"
            :menu-props="{ bottom: true, offsetY: true }"
            :rules="[(v) => !!v || 'Campo Requerido']"
            :items="sucursales"
            item-text="name"
            item-value="id"
            v-model="titular_pago.sucursal"
            dense
          ></v-select>
          <div v-else class="skeleton-loader"></div>
        </v-col>
        <!-- FIN SUCURSAL -->

        <!-- TIPO DE CUENTA -->
        <v-col cols="12" sm="3">
          <label for="">Tipo de cuenta</label>
          <v-select
            outlined
            v-if="!cargando_tipos_cuenta"
            :menu-props="{ bottom: true, offsetY: true }"
            :rules="[(v) => !!v || 'Campo Requerido']"
            :items="tipos_de_cuenta"
            item-text="TipoBancoCuenta"
            item-value="IdTipoCuenta"
            v-model="titular_pago.tipo_cuenta"
            dense
          ></v-select>
          <div v-else class="skeleton-loader"></div>
        </v-col>
        <!-- FIN TIPO DE CUENTA -->

        <!-- NUMERO DE CUENTA -->
        <v-col cols="12" sm="3">
          <label for="">Número de cuenta</label>
          <v-text-field
            :rules="[(v) => !!v || 'Campo Requerido']"
            outlined
            dense
            v-model="titular_pago.numero_cuenta"
            type="number"
          ></v-text-field>
        </v-col>
        <!-- FIN NUMERO DE CUENTA -->

        <!-- TIPO DE DOCUMENTO TITULAR TARJETA-->
        <v-col cols="12" sm="3">
          <label for="">Tipo de documento</label>
          <v-select
            outlined
            v-if="!cargando_tipos_documento"
            :items="tipos_documento"
            :rules="[(v) => !!v || 'Campo Requerido']"
            :menu-props="{ bottom: true, offsetY: true }"
            item-text="tipodoc"
            item-value="idTipoDoc"
            v-model="titular_pago.tipo_documento_debito"
            dense
          ></v-select>
          <div v-else class="skeleton-loader"></div>
        </v-col>
        <!-- FIN TIPO DE DOCUMENTO TITULAR TARJETA-->

        <!-- NUMERO DOCUMENTO TITULAR TARJETA-->
        <v-col cols="12" sm="3">
          <label for="">Documento</label>
          <v-text-field
            :rules="[
              (v) => !!v || 'Campo Requerido',
              (v) => v >= 100000 || 'Tiene que tener al menos 6 digitos',
            ]"
            outlined
            dense
            v-model="titular_pago.numero_documento_debito"
            type="number"
          ></v-text-field>
        </v-col>
        <!-- FIN NUMERO DOCUMENTO TITULAR TARJETA -->

        <!-- NOMBRE TITULAR TARJETA -->
        <v-col cols="12" sm="3">
          <label for="">Nombre Titular</label>
          <v-text-field
            :rules="[
              (v) => !!v || 'Campo Requerido',
              (v) =>
                /^[a-zA-Z ]*$/.test(v) ||
                'Debe ser solo texto sin caracteres especiales',
            ]"
            outlined
            dense
            v-model="titular_pago.nombre_titular_debito"
          ></v-text-field>
        </v-col>
        <!-- FIN NOMBRE TITULAR TARJETA  -->
      </v-row>
    </v-form>

    <!-- <v-col>
      <label for="" style="font-weight: 600; font-size: 14px"
        >Forma de pago primer factura</label
      >
      <v-select outlined dense></v-select>
    </v-col> -->

    <v-divider class="mb-3"></v-divider>

    <!-- COMO DESEA RECIBIR LA FACTURA -->
    <p class="parrafos mt-5">
      <b>Nota:</b>Indique como desea recibir su factura
    </p>
    <v-row class="d-flex" justify="center">
      <v-col lg="4"></v-col>
      <v-col lg="1">
        <v-checkbox
          v-model="titular_pago.facturacion_fisica"
          label="Física"
          color="indigo"
          class="mr-lg-15"
        ></v-checkbox>
      </v-col>
      <v-col lg="1">
        <v-checkbox
          v-model="titular_pago.facturacion_electronica"
          label="Electronica"
          color="indigo"
          class="ml-lg-6"
        ></v-checkbox>
      </v-col>
      <v-col lg="5"></v-col>
    </v-row>
    <!--FIN  COMO DESEA RECIBIR LA FACTURA -->

    <!-- BOTON COMPLETAR AFILIACION -->
    <v-row>
      <v-col>
        <v-btn
          class="primary"
          @click="abrirModalContrato()"
          :loading="guardando_datos_contrato"
          :disabled="
            !validarCompletarAfiliacion ||
            cargando_medios_pago ||
            cargando_periodos_debito
          "
        >
          Completar Afiliación</v-btn
        >
      </v-col>
    </v-row>
    <!-- FIN BOTON COMPLETAR AFILIACION -->
  </v-card>
</template>
<script>
import afiliacionService from "../services/afiliacionService";
export default {
  props: [
    "titular_pago",
    "puede_afiliarse",
    "cantidad_beneficiarios",
    "cantidad_titulares",
    "guardando_datos_contrato",
  ],
  data: () => ({
    cargando_periodos_pago: false,
    periodos_pago: [],

    cargando_medios_pago: false,
    medios_pago: [],

    cargando_periodos_debito: false,
    periodos_debito: [],

    cargando_bancos: false,
    bancos: [],

    cargando_sucursales: false,
    sucursales: [],

    cargando_tipos_cuenta: false,

    cargando_tipos_cuenta: false,
    tipos_de_cuenta: [],

    cargando_tipos_documento: false,
    tipos_documento: [],

    debito_automatico: false,
  }),
  created() {
    this.cargarDatosVista();
  },
  watch: {
    //Se detecta los cambios generados en los datos del pago del titular
    titular_pago: {
      handler() {
        if (this.medios_pago.length > 0) {
          this.medios_pago.forEach((element) => {
            if (element.id_medio_pago == this.titular_pago.medio_pago) {
              //Se activa o desactiva los datos del debito automatico dependiendo de lo que tenga asignado el medio de pago
              this.debito_automatico = element.debito_automatico;
              //Se emite el evento que cambia los datos del titular que se encuentra en el array de titulares
              this.$emit("cambio-debito-automatico", element.debito_automatico);
            }
          });
        }
      },
      //Quere decir que va detectar cualquier cambio interno dentro del objeto
      deep: true,
    },
  },
  computed: {
    /**Funcion que valida los parametros necesarios para que se puede afiliar el usuario */
    validarCompletarAfiliacion() {
      if (
        this.puede_afiliarse &&
        this.cantidad_beneficiarios > 0 &&
        this.cantidad_titulares > 0 && 
        (this.titular_pago.facturacion_electronica || this.titular_pago.facturacion_fisica)
      ) {
        return true;
      }
    },
  },
  methods: {
    /**Funcion que valida el campo del periodo de pago */
    cambioPeriodoPago() {
      //Se itera sobre todos los periodos de pago existentes
      this.periodos_pago.forEach((periodo_pago) => {
        //Se validan si coinciden los ids
        if (periodo_pago.id == this.titular_pago.periodo_pago) {
          //Se emite el evento del cambio del periodo de pago para el componente del sideCardDatosPlan.vue
          this.$emit("cambio-periodo-pago", periodo_pago);
        }
      });
    },
    /**Funcion que valida que los datos del formulario de pago esten llenos y emite el evento para abriri el modal con el contrato */
    abrirModalContrato() {
      if (this.$refs.formularioPago.validate()) {
        this.$emit("abrir-modal-contrato");
      }
    },
    /**Funcion que trae todos los datos de la vista */
    cargarDatosVista() {
      //SE TRAEN LOS MEDIOS DE PAGO
      this.cargando_medios_pago = true;
      afiliacionService
        .getMediosPago()
        .then((response) => {
          this.medios_pago = response.data;
          this.cargando_medios_pago = false;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer los metodos pago",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_medios_pago = false;
        });

      //SE TRAE LOS PERIODOS DE PAGO
      this.cargando_periodos_pago = true;
      afiliacionService
        .getPeriodosPago()
        .then((response) => {
          
          if (response.data.length > 0) {
          response.data.forEach((periodo_pago_iterar) => {
            console.log(periodo_pago_iterar);
            Object.assign(periodo_pago_iterar, {
              texto_periodo_pago:
                periodo_pago_iterar.periodo_pago +
                (periodo_pago_iterar.descuento_periodo_pago != null
                  ? " (" + periodo_pago_iterar.descuento_periodo_pago + ")"
                  : ""),
            });
          });


        }
        this.periodos_pago = response.data;
          this.cargando_periodos_pago = false;
          this.cambioPeriodoPago();
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer los periodos de pago",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_periodos_pago = false;
        });

      //SE TRAE LOS PERIODOS DEBITO
      this.cargando_periodos_debito = true;
      afiliacionService
        .getPeriodosDebito()
        .then((response) => {
          this.periodos_debito = response.data;
          this.cargando_periodos_debito = false;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer los periodos debito",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_periodos_debito = false;
        });

      //SE TRAEN LOS BANCOS
      this.cargando_bancos = true;
      afiliacionService
        .getBancos()
        .then((response) => {
          this.bancos = response.data;
          this.cargando_bancos = false;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer los bancos",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_bancos = false;
        });

      //SE TRAEN LAS SUCURSALES
      this.cargando_sucursales = true;
      afiliacionService
        .getCiudadesConConvertura()
        .then((response) => {
          this.sucursales = response.data;
          this.cargando_sucursales = false;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer las sucursales",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_sucursales = false;
        });

      //SE TRAEN LAS TIPOS DE CUENTAS BANCARIAS
      this.cargando_tipos_cuenta = true;
      afiliacionService
        .getTiposCuentasBancarias()
        .then((response) => {
          this.tipos_de_cuenta = response.data;
          this.cargando_tipos_cuenta = false;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer los tipos de cuentas bancarias",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_tipos_cuenta = false;
        });

      //Se traen todos los tipos de documentos
      this.cargando_tipos_documento = true;
      afiliacionService
        .getTiposDocumentos()
        .then((response) => {
          this.cargando_tipos_documento = false;
          this.tipos_documento = response.data;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer los tipos de documentos",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.skeleton-loader {
  border-radius: 3px;
  width: 100%;
  height: 39px;
  display: block;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}
</style>
