<template>
  <v-card>
    <v-form ref="formularioTitular">
      <v-row class="mt-2">
        <!-- PRIMER NOMBRE -->
        <v-col cols="12" lg="3" class="mb-n8">
          <label for=""
            >Primer Nombre<span class="campo_obligatorio">*</span></label
          >
          <v-text-field
            outlined
            dense
            v-model="titular.primer_nombre"
            :rules="[
              (v) => !!v || 'Campo Requerido',
              (v) =>
                /^[a-zA-Z ]*$/.test(v) ||
                'Debe ser solo texto sin caracteres especiales',
            ]"
          ></v-text-field>
        </v-col>

        <!-- SEGUNDO NOMBRE -->
        <v-col cols="12" lg="3" class="mb-n8">
          <label for="">Segundo Nombre</label>
          <v-text-field
            outlined
            dense
            v-model="titular.segundo_nombre"
            :rules="[
              (v) =>
                /^[a-zA-Z ]*$/.test(v) ||
                'Debe ser solo texto sin caracteres especiales',
            ]"
          ></v-text-field>
        </v-col>

        <!-- PRIMER APELLIDO -->
        <v-col cols="12" lg="3" class="mb-n8">
          <label for=""
            >Primer Apellido<span class="campo_obligatorio">*</span></label
          >
          <v-text-field
            outlined
            dense
            v-model="titular.primer_apellido"
            :rules="[
              (v) => !!v || 'Campo Requerido',
              (v) =>
                /^[a-zA-Z ]*$/.test(v) ||
                'Debe ser solo texto sin caracteres especiales',
            ]"
          ></v-text-field>
        </v-col>

        <!-- SEGUNDO APELLIDO -->
        <v-col cols="12" lg="3" class="mb-n8">
          <label for=""
            >Segundo Apellido<span class="campo_obligatorio">*</span></label
          >
          <v-text-field
            outlined
            dense
            v-model="titular.segundo_apellido"
            :rules="[
              (v) => !!v || 'Campo Requerido',
              (v) =>
                /^[a-zA-Z ]*$/.test(v) ||
                'Debe ser solo texto sin caracteres especiales',
            ]"
          ></v-text-field>
        </v-col>

        <!-- SEXO -->
        <v-col cols="12" lg="3" class="mb-n8">
          <label for="">Sexo<span class="campo_obligatorio">*</span></label>
          <v-select
            outlined
            v-if="!cargando_generos"
            :items="generos"
            item-text="genero"
            item-value="id"
            :menu-props="{ bottom: true, offsetY: true }"
            :rules="[(v) => !!v || 'Campo Requerido']"
            v-model="titular.genero"
            dense
          ></v-select>
          <div v-else class="skeleton-loader"></div>
        </v-col>

        <!-- FECHA DE NACIMIENTO -->
        <v-col cols="12" lg="3" class="mb-n8">
          <label for=""
            >Fecha de Nacimiento<span class="campo_obligatorio">*</span></label
          >
          <v-menu
            v-model="picker_fecha_nacimiento"
            :close-on-content-click="false"
            :nudge-right="40"
            dense
            outlined
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="titular.fecha_nacimiento"
                dense
                outlined
                append-icon="mdi-calendar"
                @click:append="picker_fecha_nacimiento = true"
                readonly
                :rules="[(v) => !!v || 'Campo Requerido']"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              min="1923-01-01"
              locale="es"
              v-model="titular.fecha_nacimiento"
              @input="picker_fecha_nacimiento = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <!-- ESTADO CIVIL -->
        <v-col cols="12" lg="3" class="mb-n8">
          <label for=""
            >Estado Civil<span class="campo_obligatorio">*</span></label
          >
          <v-select
            v-if="!cargando_estados_civiles"
            outlined
            dense
            :menu-props="{ bottom: true, offsetY: true }"
            :items="estados_civiles"
            item-text="estado_civil"
            item-value="id"
            v-model="titular.estado_civil"
            :rules="[(v) => !!v || 'Campo Requerido']"
          ></v-select>
          <div v-else class="skeleton-loader"></div>
        </v-col>

        <!-- PROFESION/OCUPACIÓN -->
        <v-col cols="12" lg="3" class="mb-n8">
          <label for=""
            >Profesión/Ocupación<span class="campo_obligatorio">*</span></label
          >
          <v-select
            v-if="!cargando_profesiones"
            outlined
            dense
            :menu-props="{ bottom: true, offsetY: true }"
            :items="profesiones"
            item-value="id"
            item-text="profesion"
            v-model="titular.profesion"
            :rules="[(v) => !!v || 'Campo Requerido']"
          ></v-select>
          <div v-else class="skeleton-loader"></div>
        </v-col>

        <!-- ESTRATO -->
        <v-col cols="12" lg="3" class="mb-n8">
          <label for="">Estrato<span class="campo_obligatorio">*</span></label>
          <v-text-field
            outlined
            dense
            type="number"
            min="1"
            max="6"
            v-model="titular.estrato"
            :rules="[
              (value) => value <= 6 || 'El estrato maximo es 6',
              (value) => value > 0 || 'El estrato minimo es 1',
            ]"
          ></v-text-field>
        </v-col>

        <!-- DIRECCIÓN -->
        <v-col cols="12" lg="6" class="mb-n8">
          <label for=""
            >Dirección<span class="campo_obligatorio">*</span></label
          >
          <v-text-field
            outlined
            dense
            append-icon="mdi-map-marker"
            readonly
            @click="modal_direccion_titular = true"
            @click:append="modal_direccion_titular = true"
            v-model="titular.direccion_completa"
            :rules="[(v) => !!v || 'Campo Requerido']"
          ></v-text-field>
        </v-col>

        <!-- TELEFONO CASA -->
        <v-col cols="12" lg="3" class="mb-n8">
          <label for="">Teléfono Casa</label>
          <v-text-field
            type="number"
            outlined
            dense
            v-model="titular.telefono"
          ></v-text-field>
        </v-col>

        <!-- CORREO CASA -->
        <v-col cols="12" lg="3" class="mb-n8">
          <label for=""
            >Correo Electrónico<span class="campo_obligatorio">*</span></label
          >
          <v-text-field
            outlined
            dense
            v-model="titular.correo"
            :rules="[
              (v) =>
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                'E-mail debe ser valido',
            ]"
          ></v-text-field>
        </v-col>

        <!-- ANEXO -->
        <v-col cols="12" lg="4" class="mb-n8">
          <label for="">Anexos<span class="campo_obligatorio">*</span></label>
          <br />
          <v-file-input
            label="Subir Archivo"
            multiple
            chips
            append-icon="mdi-paperclip"
            v-model="titular.anexos"
            prepend-icon=""
            outlined
            dense
            color="primary"
          ></v-file-input>
        </v-col>
      </v-row>

      <!-- CHECKBOX PARA VALIDAR SI EL TITULAR SERA USUARIO DE RED MEDICA VITAL -->
      <v-row>
        <v-col class="mb-n8">
          <v-checkbox
            class="ml-2"
            v-model="titular.es_beneficiario"
            :label="`El titular será Usuario de Red Medica Vital.`"
          ></v-checkbox>
        </v-col>
      </v-row>
      <!-- FIN CHECKBOX -->

      <!-- REGIMEN, EPS, ASEGURABILIDAD -->

      <!-- SE ACTIVAN CON EL CHECKBOX  -->
      <v-row v-if="titular.es_beneficiario">
        <v-col cols="12" lg="3" class="mb-n8">
          <label for=""
            >Regimen al que pertenece<span class="campo_obligatorio"
              >*</span
            ></label
          >
          <v-select
            outlined
            v-if="!cargando_regimenes"
            v-model="titular.regimen"
            :menu-props="{ bottom: true, offsetY: true }"
            :items="regimenes"
            item-text="regimen"
            item-value="id"
            :rules="[(v) => !!v || 'Campo requerido']"
            dense
            v-on:change="activarModalRegimen()"
          ></v-select>
          <div v-else class="skeleton-loader"></div>
        </v-col>
        <v-col cols="12" lg="3" class="mb-n8">
          <label for="">EPS<span class="campo_obligatorio">*</span></label>
          <v-autocomplete
            outlined
            v-if="!cargando_items_eps"
            v-model="titular.eps"
            :menu-props="{ bottom: true, offsetY: true }"
            :items="items_eps"
            item-text="epsnom"
            item-value="epscod"
            :rules="[(v) => !!v || 'Campo Requerido']"
            dense
            v-on:change="activarModalInformativo()"
          ></v-autocomplete>
          <div v-else class="skeleton-loader"></div>
        </v-col>

        <!-- ASEGURABILIDAD -->
        <v-col cols="12" lg="3" class="mb-n8">
          <label for=""
            >Asegurabilidad<span class="campo_obligatorio">*</span></label
          >
          <v-text-field
            v-model="titular.asegurabilidad_texto"
            v-if="!cargando_enfermedades_asegurabilidad"
            append-icon="mdi-clipboard-arrow-up"
            @click:append="abrirModalAsegurabilidad('titular')"
            @click="abrirModalAsegurabilidad('titular')"
            :rules="[(v) => !!v.length || 'Campo Requerido']"
            multiple
            readonly
            placeholder="Indicar Asegurabilidad"
            dense
            outlined
          ></v-text-field>
          <div v-else class="skeleton-loader"></div>
        </v-col>
      </v-row>
      <!-- FIN ASEGURABILIDAD -->

      <!-- BOTON PARA CONTINUAR Y GUARDAR EL TITULAR -->

      <v-col class="d-flex">
        <v-btn
          class="primary mt-lg-6"
          :disabled="!validarBotonContinuar"
          :loading="guardando_datos_titular"
          @click="guardarTitular()"
          >{{ editar_titular ? "Guardar" : "Continuar" }}</v-btn
        >
        <v-btn
          v-if="editar_titular"
          :disabled="guardando_datos_titular"
          dark
          class="red mt-lg-6 ml-3"
          @click="cancelarEditarTitular()"
          >Cancelar</v-btn
        >
      </v-col>
    </v-form>

    <v-dialog v-model="modal_direccion_titular" width="900">
      <ModalDireccion
        v-if="modal_direccion_titular"
        :json_direccion="titular.direccion"
        @guardar_direccion="guardarDireccionTitular"
        @cerrar="modal_direccion_titular = false"
      />
    </v-dialog>
  </v-card>
</template>
<script>
import ModalDireccion from "../../general/components/ModalDireccion.vue";
import afiliacionService from "../services/afiliacionService.js";
export default {
  //Props que requieren los datos del titular
  props: {
    titular: {
      type: Object,
      required: true,
    },
    editar_titular: { type: Boolean },
    guardando_datos_titular: {},
  },
  data: () => ({
    //-----------INPUTS FORMULARIO ----------------

    //NOTA: Variables con seudo nombre "cargando" quieren decir que esta variables activan los skeleton-loaders de los campos  mientras se traen los datos de db

    picker_fecha_nacimiento: false,

    cargando_generos: false,
    generos: [],

    cargando_estados_civiles: false,
    estados_civiles: [],

    cargando_profesiones: false,
    profesiones: [],

    cargando_regimenes: false,
    regimenes: [],

    cargando_items_eps: false,
    items_eps: [],

    cargando_enfermedades_asegurabilidad: false,
    enfermedades_asegurabilidad: [],

    //------------- MODALES ----------------------

    modal_direccion_titular: false,
  }),
  components: {
    ModalDireccion,
  },
  created() {
    this.cargarDatosVista();
  },

  computed: {
    /**Funcion que valida que el regimen no sea 2(Subsidiado) para poder continuar */
    validarBotonContinuar() {
      if (this.titular.regimen != 2) {
        return true;
      }
    },
  },
  methods: {
    /**Funcion que valida y guarda los datos del ingresados sobre el titular */
    guardarTitular() {
      //Se valida que todas los inputs cumplan con los requisitos que se les puso
      if (this.$refs.formularioTitular.validate()) {
        //Se valida de nuevo que el regimen no sea 2(Subsidiado) por seguridad
        if (this.titular.regimen != 2) {
          //Se valida que la persona a ser titular sea mayor de 18 años
          if (this.calcularEdad(this.titular.fecha_nacimiento) >= 18) {
            //Se emite el evento de guardar el titular al componente padre AfiliacionFamiliar.vue
            this.$emit("guardar-titular");
          } else {
            this.$swal.fire({
              title: "El titular debe ser mayor de 18 años",
              icon: "info",
              allowEscapeKey: false,
              allowOutsideClick: false,
              allowEnterKey: false,
              position: "center",
              showConfirmButton: true,
              confirmButtonText: "OK",
            });
          }
        } else {
          //Se emite el evento de validar-regimen que advierte que el regimen no puede ser subsidiado segun las normas de la empresa
          this.$emit("activar-modal-informativo", "validar-regimen-titular");
        }
      }
    },

    /**Funcion que emite el evento al cancelar el editar el titular */
    cancelarEditarTitular() {
      this.$swal
        .fire({
          title: "Estas seguro de cancelar?",
          text: "Se perderan los datos no guardados",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Confirmar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$emit("cancelar-editar-titular");
          }
        });
    },

    /**Funcion que emite el evento para guardar la direccion del titular
     * @param {object} direccion direccion a guardar
     */
    guardarDireccionTitular(direccion) {
      this.$emit("guardar-direccion-titular", direccion);
    },

    /**
     * Funcion que retorna la edad de una persona apartir de su fecha de nacimiento
     * @param {string} fecha_nacimiento la fecha de nacimiento a la que se le va calcular la edad
     * @return {number} la edad de la persona
     */
    calcularEdad(fecha_nacimiento) {
      //Se obtiene la fecha actual en formato Date https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/Date
      var hoy = new Date();

      //Se obtiene la fecha en formato Date
      var cumpleanos = new Date(fecha_nacimiento);

      //Al hacer pruebas se observo que retornaba la fecha con un dia menos por lo que se le agrega un dia a la fecha obtenida
      cumpleanos.setDate(cumpleanos.getDate() + 1);

      //Se obtiene en primera estancia la edad restanto el año actual con el año de la fecha recibida por parametros
      var edad = hoy.getFullYear() - cumpleanos.getFullYear();

      //Se resta el mes actual y el mes de la fecha de cumpleaños para determinar si este ya paso
      var m = hoy.getMonth() - cumpleanos.getMonth();

      //Se valida que si el mes de la fecha de cumpleaños no ha pasado se le reste a la edad 1
      //O
      //En el caso de que el mes sea igual al actual( m == 0) y el dia de actual sea menor al de la fecha recibida se le resta a la edad 1 tambien
      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
      }

      //Se retorna la edad
      return edad;
    },

    /**Funcion que emite el evento en el componente padre para abrir el modal asegurabilidad con todas las enfermedades que le pertenece*/
    abrirModalAsegurabilidad(titulo) {
      //Se emite el evento
      this.$emit(
        "abrir-modal-asegurabilidad",
        titulo,
        this.enfermedades_asegurabilidad
      );
    },
    /**Funcion que emite el evento en el componente padre para activar el modal informativo cada que se cambia de eps */
    activarModalInformativo() {
      //Se emite el evento
      this.$emit("activar-modal-informativo", "validar-eps-titular");
    },
    abrirModalDireccion() {},

    /**Funcion que se ejecuta cada vez que se cambia el regimen del titular y valida que el regimen no sea 2 subsidiado */
    activarModalRegimen() {
      if (this.titular.regimen == 2) {
        //Se emite el evento de validar-regimen que advierte que el regimen no puede ser subsidiado segun las normas de la empresa
        this.$emit("activar-modal-informativo", "validar-regimen-titular");
      }
    },

    /**Funcion que trae todos los datos de la vista de manera asincrona */
    cargarDatosVista() {
      //SE TRAEN LOS GENEROS
      this.cargando_generos = true;
      afiliacionService
        .getGeneros()
        .then((response) => {
          this.generos = response.data;
          this.cargando_generos = false;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer los generos",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_generos = false;
        });

      //SE TRAE LAS PROFESIONES EN DB
      this.cargando_profesiones = true;
      afiliacionService
        .getProfesiones()
        .then((response) => {
          this.cargando_profesiones = false;
          this.profesiones = response.data;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer las profesiones",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_profesiones = false;
        });

      //SE TRAE LOS ESTADOS CIVILES
      this.cargando_estados_civiles = true;
      afiliacionService
        .getEstadosCiviles()
        .then((response) => {
          this.cargando_estados_civiles = false;
          this.estados_civiles = response.data;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer los estados civiles",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_estados_civiles = false;
        });

      //SE TRAE LOS REGIMENES
      this.cargando_regimenes = true;
      afiliacionService
        .getRegimenes()
        .then((response) => {
          this.cargando_regimenes = false;
          this.regimenes = response.data;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer los regimenes",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_regimenes = false;
        });

      //SE TRAE LAS EPS EN DB
      this.cargando_items_eps = true;
      afiliacionService
        .getEps()
        .then((response) => {
          this.items_eps = response.data;
          this.cargando_items_eps = false;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer los items-eps",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_items_eps = false;
        });

      //SE TRAE LAS ENFERMEDADADES DE ASEGURABILIDAD EN DB
      this.cargando_enfermedades_asegurabilidad = true;
      afiliacionService
        .getEnfermedadesAsegurabilidad()
        .then((response) => {
          this.enfermedades_asegurabilidad = response.data;
          this.cargando_enfermedades_asegurabilidad = false;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer las enfermedades asegurabilidad",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_enfermedades_asegurabilidad = false;
        });
    },
  },
};
</script>
<style scoped>
.skeleton-loader {
  border-radius: 3px;
  width: 100%;
  height: 39px;
  display: block;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}
</style>
