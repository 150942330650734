<template>
    <v-card>
            <v-card-title class="text-h5 justify-center">
              Plan Familiar
            </v-card-title>
            <v-card-text>
              <p class="texto">
                Este es un valor que pagarás por única vez para adquirir el
                derecho a tu afiliación a Red Médica Vital y será el valor que
                se te cobrará en este momento. El descuento web solo aplica
                sobre el primer mes y no es acumulable.
              </p>
              <span class="texto"><b>Número Afiliados</b> </span>
              <span class="texto"> {{ numeroAfiliados }}</span> <br />
              <span class="texto"><b>Valor Afiliado</b></span>
              <span class="texto"> ${{ valorAfiliado }}</span> <br />
              <span class="texto"><b>Descuento por afiliación Web</b></span
              ><span class="texto"> ${{ descuentoAfiliacionWeb }}</span>
              <br />
              <span class="texto" style="color: green">
                <b>Total Afiliación Web</b>
              </span>
              <span class="texto"> ${{ totalAfiliacionWeb }} </span>
              <br />

              <v-divider class="mb-3 mt-3"></v-divider>

              <p class="titulo" style="text-align: center">
                Periodo de pago mensual
              </p>
              <p class="texto" style="text-align: center">
                Este será el valor que seguirás pagando mensualmente y que se te
                comenzará a cobrar a partir del próximo mes por el medio de pago
                que selecciones en el próximo paso.
              </p>

              <span class="texto"> <b>Siguiente Factura</b> </span>
              <span class="texto"> ${{ siguienteFactura }} </span> <br />
              <v-divider class="mt-3 mb-3"></v-divider>

              <v-checkbox
                v-model="botonModalPlanFamiliar"
                :label="`Acepto los términos de uso y políticas de tratamiento de datos personales`"
              ></v-checkbox>
              <v-row>
                <v-col>
                  <v-btn
                    class="primary"
                    block
                    :disabled="!botonModalPlanFamiliar"
                    @click="$emit('guardar-contrato')"
                  >
                    Guardar</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
</template>

<script>
export default {
    props: [
        'numeroAfiliados',
        'valorAfiliado',
        'descuentoAfiliacionWeb',
        'totalAfiliacionWeb',
        'siguienteFactura',
    ],
    data: () => ({
        botonModalPlanFamiliar: false,

    })
}
</script>

<style>

</style>