<template>
  <v-card class="mx-lg-7 ma-lg-5" style="text-align: center">
    <v-toolbar dark color="indigo darken-1" height="40">
      <v-spacer></v-spacer>
      <!-- INFORMACION DEL TIPO DE PLAN -->
      <p class="mt-4" style="font-size: 16px">
        Plan
        {{
          this.$route.params.id_plan == 1 ? "Familiar" : "Convenio Colectivo"
        }}
      </p>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <p class="texto">
        Este es un valor que pagarás por única vez para adquirir el derecho a tu
        afiliación a Red Médica Vital y será el valor que se te cobrará en este
        momento. El descuento web solo aplica sobre el primer mes y no es
        acumulable.
      </p>

      <v-row class="mb-n8">
        <v-col class="text-start" lg="8">
          <span class="texto"><b>Número Afiliados</b> </span>
        </v-col>
        <v-col class="text-right" lg="4">
          <span class="texto">{{ beneficiarios.length }}</span>
        </v-col>
      </v-row>
      <v-row class="mb-n8">
        <v-col class="text-start">
          <span class="texto"><b>Valor Afiliado</b> </span>
        </v-col>
        <v-col v-if="!cotizando" class="text-right">
          <span class="texto">${{ valor_afiliado | moneda }}</span>
        </v-col>
        <v-col v-else cols="3">
          <div class="skeleton-loader"></div>
        </v-col>
      </v-row>
      <v-row class="mb-n8">
        <v-col
          :cols="!cargando_descuento_afiliacion_web && !cotizando ? 8 : 9"
          class="text-start"
        >
          <span class="texto"><b>Descuento por afiliación Web</b> </span>
        </v-col>
        <v-col
          cols="4"
          v-if="!cargando_descuento_afiliacion_web && !cotizando"
          class="text-right"
        >
          <span class="texto">${{ totalDescuentoAfiliacionWeb | moneda }}</span>
        </v-col>
        <v-col v-else cols="3">
          <div class="skeleton-loader"></div>
        </v-col>
      </v-row>
      <br />
      <v-row>
        <v-col class="text-start">
          <span class="texto" style="color: green"
            ><b>Total Afiliación Web</b>
          </span>
        </v-col>
        <v-col v-if="!cotizando" class="text-right">
          <span class="texto">${{ totalAfiliacionWeb | moneda }}</span>
        </v-col>
        <v-col v-else cols="3">
          <div class="skeleton-loader"></div>
        </v-col>
      </v-row>

      <v-divider class="mb-3 mt-3"></v-divider>

      <p class="titulo" style="text-align: center">
        Periodo de pago {{ texto_periodo_pago.toLowerCase() }}
      </p>
      <p class="texto" style="text-align: center">
        Este será el valor que seguirás pagando
        {{ texto_periodo_pago.toLowerCase() + "mente" }} y que se te comenzará a
        cobrar a partir del próximo {{ proximoCobroNombre }} por el medio de
        pago que selecciones en el próximo paso.
      </p>
      <v-row>
        <v-col class="text-start">
          <span class="texto"><b>Siguiente Factura</b> </span>
        </v-col>
        <v-col v-if="!cotizando" class="text-right">
          <span class="texto">${{ siguienteFactura | moneda }}</span>
        </v-col>
        <v-col v-else cols="3">
          <div class="skeleton-loader"></div>
        </v-col>
      </v-row>

      <v-divider class="mt-3 mb-3"></v-divider>

      <div class="mt-3">
        <p class="mt-3" style="color: #3c8edf; font-weight: 900">Titular</p>
      </div>

      <!-- TARJETA DE TITULARES -->
      <div
        class="mt-2 card"
        v-for="titular in titulares"
        :key="titular.primer_nombre"
      >
        <span style="color: #000; font-weight: 500"
          >{{ titular.primer_nombre }} {{ titular.primer_apellido }}</span
        >
        <br />
        <span style="color: #000; font-weight: 400">{{
          titular.numero_documento
        }}</span>
        <v-btn
          :disabled="!puede_afiliarse || guardando_datos_contrato"
          elevation="1"
          max-width="3px"
          min-width="1"
          min-height="10px"
          max-height="22px"
          class="primary ml-4"
          small
          ><v-icon style="font-size: 16px" @click="editarTitular()"
            >mdi-pencil</v-icon
          ></v-btn
        >
      </div>

      <p class="mt-3" style="color: #3c8edf; font-weight: 900">Beneficiarios</p>

      <!-- TARJETA DE BENEFICIARIOS -->
      <div
        class="mt-2 card"
        v-for="(beneficiario, indice) in beneficiarios"
        :key="indice"
      >
        <span style="color: #000; font-weight: 500"
          >{{ beneficiario.primer_nombre }}
          {{ beneficiario.primer_apellido }}</span
        >
        <br />
        <span style="color: #000; font-weight: 400">{{
          beneficiario.numero_documento
        }}</span>
        <v-btn
          style="width: 1px"
          :disabled="!puede_afiliarse || guardando_datos_contrato"
          max-width="3px"
          min-width="1"
          max-height="22px"
          elevation="1"
          class="primary ml-4"
          small
          ><v-icon
            style="font-size: 16px"
            @click="
              editarBeneficiario(
                beneficiario.tipo_documento,
                beneficiario.numero_documento
              )
            "
            >mdi-pencil</v-icon
          ></v-btn
        >
        <v-btn
          :disabled="!puede_afiliarse || guardando_datos_contrato"
          elevation="1"
          max-width="3px"
          min-width="1"
          max-height="22px"
          class="red ml-1"
          style="width: 1px"
          small
          ><v-icon
            style="font-size: 16px"
            @click="
              eliminarBeneficiario(
                beneficiario.tipo_documento,
                beneficiario.numero_documento
              )
            "
            >mdi-delete</v-icon
          ></v-btn
        >
      </div>

      <v-divider class="mt-3 mb-3"></v-divider>
    </v-card-text>
  </v-card>
</template>
<script>
import afiliacionService from "../services/afiliacionService";

export default {
  props: [
    "titulares",
    "beneficiarios",
    "medio_pago",
    "cantidad_meses",
    "debito_automatico",
    "texto_periodo_pago",
    "tab_actual",
    "editar_titular",
    "editar_beneficiario",
    "puede_afiliarse",
    "guardando_datos_contrato",
  ],
  data: () => ({
    cotizando: false,
    valor_afiliado: 0,
    cargando_descuento_afiliacion_web: false,
    descuento_aplica_sobre_cada_beneficiario: 1,
    descuento_afiliacion_web: 0,
  }),
  async created() {
    //SE TRAE EL DESCUENTO WEB EN DB
    this.cargando_descuento_afiliacion_web = true;
    await afiliacionService
      .getDescuentoAfiliacionWeb(
        this.$store.state.roles_afiliacion_asesores.includes("asesor") ? 1 : 0
      )
      .then((response) => {
        this.descuento_afiliacion_web = response.data[0].Tarifa;
        this.descuento_aplica_sobre_cada_beneficiario =
          response.data[0].aplica_sobre_cada_beneficiario;
        this.$emit("cambio-descuento-afiliacion-web", {
          descuento_afiliacion_web: this.descuento_afiliacion_web,
          aplica_sobre_cada_beneficiario:
            this.descuento_aplica_sobre_cada_beneficiario,
        });
      })
      .catch((error) => {
        this.$swal.fire({
          title: "Error al traer el descuento afiliacion web",
          icon: "error",
          position: "center",
          showConfirmButton: true,
          confirmButtonText: "OK",
        });
        console.log(error);
      });
    this.cargando_descuento_afiliacion_web = false;

    if (this.beneficiarios.length > 0) {
      this.cotizar();
    }
  },
  computed: {
    /**Funcion que determina el precio total de la afiliacion web */
    totalAfiliacionWeb() {
      if (this.beneficiarios.length > 0 && this.valor_afiliado > 0) {
        return (
          this.valor_afiliado *
            this.beneficiarios.length *
            this.cantidad_meses -
          (this.descuento_aplica_sobre_cada_beneficiario
            ? this.descuento_afiliacion_web * this.beneficiarios.length
            : this.descuento_afiliacion_web)
        );
      } else {
        return 0;
      }
    },
    totalDescuentoAfiliacionWeb() {
      if (this.descuento_afiliacion_web != 0) {
        if (this.descuento_aplica_sobre_cada_beneficiario) {
          return this.descuento_afiliacion_web * this.beneficiarios.length;
        } else {
          return this.descuento_afiliacion_web;
        }
      } else {
        return 0;
      }
    },
    /**Funcion que determina el precio total de la siguiente factura a pagar */
    siguienteFactura() {
      return (
        this.valor_afiliado * this.beneficiarios.length * this.cantidad_meses
      );
    },
    /**Funcion que determina el nombre la nomenclatura del periodo de pago seleccionado para mostrarlo en el mensaje de la vista del sidecard*/
    proximoCobroNombre() {
      var nombre_texto = "mes";
      switch (this.cantidad_meses) {
        case 1:
          nombre_texto = "mes";
          break;
        case 6:
          nombre_texto = "semestre";
          break;
        case 12:
          nombre_texto = "año";
          break;
      }

      return nombre_texto;
    },
  },
  watch: {
    /**Observador que detecta el cambio en la cantidad de beneficiarios y cotiza el precio de la afiliacion */
    beneficiarios: function () {
      this.cotizar();
    },

    /**Observador que detecta el cambio del medio de pago del titular y cotiza el precio de la afiliacion*/
    medio_pago: function () {
      //Se pone un timeout de un 1 milisegundo para que se alcanze a detectar el cambio de debitoautomatico
      setTimeout(
        function () {
          this.cotizar();
        }.bind(this),
        100
      );
    },
  },
  methods: {
    /**Funcion que emite el evento para editar los datos del titular */
    editarTitular() {
      this.$swal
        .fire({
          title: "Estas seguro de modificar el titular?",
          text: "Se perderan los datos no guardados",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Confirmar",
        })
        .then(async (result) => {
          //Si se acepta guardar el ultimo recibo sin pagar completamente
          if (result.isConfirmed) {
            this.$emit("editar-titular");
          }
        });
    },

    /**Funcion que cotiza el precio de la afiliacion */
    async cotizar() {
      //Para empezar a cotizar debe haber al menos un beneficiario
      if (this.beneficiarios.length > 0) {
        this.cotizando = true;

        //Se llama al servicio que cotiza entonces los datos teniendo en cuenta los siguientes paramentros:
        // id_plan id del plan seleccionado Familiar o Convenio Colectivo
        // numero_personas cantidad de beneficiarios a afiliar
        // cantidad_meses cantidad de meses del periodo de pago seleccionado (mensual = 1,semestral = 6,anual = 12)
        // id_medio_pago medio de pago seleccionado Otros o Debito Automatico
        // debito_automatico  si el medio de pago es debito automatico se aplicara un descuento especial
        // es_alianza no definido hasta el momento
        // es_asesor si el usuario logueado tiene el rol de asesor o no ya que aplican otros terminos para los asesores

        await afiliacionService
          .generarCotizacion({
            id_plan: this.$route.params.id_plan == 1 ? 1 : 2,
            numero_personas: this.beneficiarios.length,
            cantidad_meses: this.cantidad_meses,
            id_medio_pago: this.medio_pago,
            debito_automatico: this.debito_automatico,
            es_alianza: false,
            es_asesor:
              this.$store.state.roles_afiliacion_asesores.includes("asesor"),
          })
          .then((response) => {
            //Se asigna el valor por afiliado correspondiente
            this.valor_afiliado = response.data.tarifa;

            //Se asigna el valor del descuento al que aplica
            this.descuento_afiliacion_web =
              response.data.descuento_afiliacion_web;

            this.descuento_aplica_sobre_cada_beneficiario =
              response.data.descuento_afiliacion_web_sobre_cada_beneficiario;

            this.$emit("cambio-descuento-afiliacion-web", {
              descuento_afiliacion_web: this.descuento_afiliacion_web,
              aplica_sobre_cada_beneficiario:
                this.descuento_aplica_sobre_cada_beneficiario,
            });
          })
          .catch((error) => {
            this.$swal.fire({
              title: "Error al generar la cotizacion",
              icon: "error",
              position: "center",
              showConfirmButton: true,
              confirmButtonText: "OK",
            });
            console.log(error);
          });
        this.cotizando = false;
      } else {
        //En el caso de que no hayan beneficiarios se asignan los siguientes valores
        this.valor_afiliado = 0;
        this.total_afiliacion_web = 0;
        this.siguiente_factura = 0;
      }
    },

    /**
     * Funcion que emite el evento para editar los datos de un beneficiario
     * @param {number} tipo_documento tipo de documento del beneficiario
     * @param {number} numero_documento numero del documento del beneficiario
     */
    editarBeneficiario(tipo_documento, numero_documento) {
      this.$swal
        .fire({
          title: "Estas seguro de modificar el beneficiario?",
          text:
            this.tab_actual == "tab-2"
              ? "Se eliminaran todos los datos no guardados"
              : "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Confirmar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$emit("editar-beneficiario", tipo_documento, numero_documento);
          }
        });
    },

    /**
     * Funcion que emite el evento para eliminar los datos de un beneficiario
     * @param {number} tipo_documento tipo de documento del beneficiario
     * @param {number} numero_documento numero del documento del beneficiario
     */
    eliminarBeneficiario(tipo_documento, numero_documento) {
      let mensaje = "Estas seguro de eliminar el beneficiario?";

      if (numero_documento == this.titulares[0].numero_documento) {
        mensaje += "(El titular dejara de ser usuario de red medica)";
      }

      this.$swal
        .fire({
          title: mensaje,
          text: "Se eliminaran todos sus datos",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Confirmar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$emit(
              "eliminar-beneficiario",
              tipo_documento,
              numero_documento
            );
          }
        });
    },
  },
};
</script>
<style scoped>
.skeleton-loader {
  border-radius: 3px;
  width: 100%;
  height: 80%;
  display: block;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}
.texto {
  color: #000;
  font-size: 1.2em;
}
.titulo {
  color: #000;
  text-align: justify;
  font-size: 19px;
  font-weight: 600;
}
.card {
  padding: 8px;
  border: 1px solid rgb(221, 220, 220);
  border-radius: 8px;
}
</style>
