<template>
  <v-card flat style="text-align: center">
    <div v-if="mostrar_datos_basicos_agregar_beneficiario">
      <v-row class="mt-3 justify-center">
        <!-- TIPO DE DOCUMENTO -->
        <v-col cols="12" lg="4">
          <label for=""
            >Tipo de documento<span class="campo_obligatorio">*</span></label
          >
          <v-select
            v-if="!cargando_tipos_documento"
            :disabled="campo_desactivado"
            :items="tipos_documento"
            item-text="tipodoc"
            item-value="idTipoDoc"
            v-model="beneficiario.tipo_documento"
            :menu-props="{ bottom: true, offsetY: true }"
            outlined
            dense
            :rules="[(v) => !!v || 'Campo requerido']"
          ></v-select>
          <div v-else class="skeleton-loader"></div>
        </v-col>
        <!-- FIN TIPO DE DOCUMENTO -->

        <!-- NUMERO DE DOCUMENTO -->
        <v-col cols="12" lg="4">
          <label for=""
            >Número de documento<span class="campo_obligatorio">*</span></label
          >
          <v-text-field
            :disabled="campo_desactivado"
            outlined
            type="number"
            dense
            v-model="beneficiario.numero_documento"
            :rules="[
              (v) => !!v || 'Campo requerido',
              (v) => v >= 100000 || 'Tiene que tener al menos 6 digitos',
            ]"
          ></v-text-field>
        </v-col>
        <!-- FIN NUMERO DE DOCUMENTO  -->
      </v-row>

      <!-- BOTON AGREGAR BENEFICIARIO -->
      <v-col>
        <v-btn
          class="primary"
          :loading="validando_documento_beneficiario"
          :disabled="!validarDocumentoBeneficiario || campo_desactivado"
          @click="validarDatosBasicosBeneficiario()"
          >Agregar Usuario
        </v-btn>
      </v-col>
      <!-- FIN BOTON AGREGAR BENEFICIARIO -->
    </div>

    <!-- AGREGAR NUEVO BENEFICIARIO O PASAR A PAGAR -->
    <div v-if="agregar_nuevo_beneficiario_o_pagar">
      <v-row class="d-flex justify-center">
        <v-col cols="8" class="mb-n3">
          <v-btn
            block
            class="primary mt-lg-6"
            @click="agregarOtroBeneficiario()"
            >Agregar Otro Usuario</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="8">
          <v-btn block class="success mt-lg-6" @click="pago()">Pagar </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- FIN AGREGAR NUEVO BENEFICIARIO O PASAR A PAGAR -->

    <!-- FORMULARIO AGREGAR BENEFICIARIO -->
    <div v-show="mostrar_formulario_agregar_beneficiario">
      <v-form ref="formularioBeneficiario">
        <v-row class="mt-2">
          <!-- PRIMER NOMBRE -->
          <v-col cols="12" lg="3" class="mb-n8">
            <label for=""
              >Primer Nombre<span class="campo_obligatorio">*</span></label
            >
            <v-text-field
              outlined
              dense
              v-model="beneficiario.primer_nombre"
              :rules="[
                (v) => !!v || 'Campo requerido',
                (v) =>
                  /^[a-zA-Z ]*$/.test(v) ||
                  'Debe ser solo texto sin caracteres especiales',
              ]"
            ></v-text-field>
          </v-col>
          <!-- FIN PRIMER NOMBRE -->

          <!-- SEGUNDO NOMBRE -->
          <v-col cols="12" lg="3" class="mb-n8">
            <label for="">Segundo Nombre</label>
            <v-text-field
              outlined
              dense
              v-model="beneficiario.segundo_nombre"
              :rules="[
                (v) =>
                  /^[a-zA-Z ]*$/.test(v) ||
                  'Debe ser solo texto sin caracteres especiales',
              ]"
            ></v-text-field>
          </v-col>
          <!-- FIN SEGUNDO NOMBRE -->

          <!-- PRIMER APELLIDO -->
          <v-col cols="12" lg="3" class="mb-n8">
            <label for=""
              >Primer Apellido<span class="campo_obligatorio">*</span></label
            >
            <v-text-field
              outlined
              dense
              v-model="beneficiario.primer_apellido"
              :rules="[
                (v) => !!v || 'Campo requerido',
                (v) =>
                  /^[a-zA-Z ]*$/.test(v) ||
                  'Debe ser solo texto sin caracteres especiales',
              ]"
            ></v-text-field>
          </v-col>
          <!-- FIN PRIMER APELLIDO -->

          <!-- SEGUNDO APELLIDO -->
          <v-col cols="12" lg="3" class="mb-n8">
            <label for=""
              >Segundo Apellido<span class="campo_obligatorio">*</span></label
            >
            <v-text-field
              outlined
              dense
              v-model="beneficiario.segundo_apellido"
              :rules="[
                (v) => !!v || 'Campo requerido',
                (v) =>
                  /^[a-zA-Z ]*$/.test(v) ||
                  'Debe ser solo texto sin caracteres especiales',
              ]"
            ></v-text-field>
          </v-col>
          <!--  FIN SEGUNDO APELLIDO -->

          <!-- GENERO -->
          <v-col cols="12" lg="3" class="mb-n8">
            <label for="">Sexo<span class="campo_obligatorio">*</span></label>
            <v-select
              outlined
              v-if="!cargando_generos"
              :items="generos"
              item-text="genero"
              item-value="id"
              :menu-props="{ bottom: true, offsetY: true }"
              :rules="[(v) => !!v || 'Campo Requerido']"
              v-model="beneficiario.genero"
              dense
            ></v-select>
            <div v-else class="skeleton-loader"></div>
          </v-col>
          <!-- FIN GENERO  -->

          <!-- FECHA NACIMIENTO  -->
          <v-col cols="12" lg="3" class="mb-n8">
            <label for=""
              >Fecha de Nacimiento<span class="campo_obligatorio"
                >*</span
              ></label
            >
            <v-menu
              v-model="picker_fecha_nacimiento"
              :close-on-content-click="false"
              :nudge-right="40"
              dense
              outlined
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="beneficiario.fecha_nacimiento"
                  dense
                  outlined
                  readonly
                  :rules="[(v) => !!v || 'Campo Requerido']"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="beneficiario.fecha_nacimiento"
                min="1923-01-01"
                @input="picker_fecha_nacimiento = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!-- FIN FECHA NACIMIENTO -->

          <!-- ESTRATO -->
          <v-col cols="12" lg="3" class="mb-n8">
            <label for=""
              >Estrato<span class="campo_obligatorio">*</span></label
            >
            <v-text-field
              outlined
              dense
              type="number"
              v-model="beneficiario.estrato"
              :rules="[
                (value) => value <= 6 || 'El estrato maximo es 6',
                (value) => value >= 0 || 'El estrato minimo es 1',
              ]"
            ></v-text-field>
          </v-col>
          <!-- FIN ESTRATO -->

          <!-- TELEFONO DE CONTACTO -->
          <v-col cols="12" lg="3" class="mb-n8">
            <label for="">Teléfono de Contacto</label>
            <v-text-field
              outlined
              type="number"
              dense
              v-model="beneficiario.telefono"
            ></v-text-field>
          </v-col>
          <!-- TELEFONO DE CONTACTO -->

          <!-- TELEFONO CELULAR -->
          <v-col cols="12" lg="3" class="mb-n8">
            <label for=""
              >Teléfono Celular<span class="campo_obligatorio">*</span></label
            >
            <v-text-field
              outlined
              dense
              type="number"
              v-model="beneficiario.celular"
              :rules="[
                (v) => !!v || 'Campo Requerido',
                (v) => v >= 1000000000 || 'Tiene que tener al menos 10 digitos',
              ]"
            ></v-text-field>
          </v-col>
          <!-- FIN TELEFONO CELULAR -->

          <!-- EPS -->
          <v-col cols="12" lg="3" class="mb-n8">
            <label for="">EPS<span class="campo_obligatorio">*</span></label>
            <v-autocomplete
              outlined
              v-if="!cargando_items_eps"
              v-model="beneficiario.eps"
              :menu-props="{ bottom: true, offsetY: true }"
              :items="items_eps"
              item-text="epsnom"
              item-value="epscod"
              :rules="[(v) => !!v || 'Campo Requerido']"
              dense
              v-on:change="activarModalInformativo('validar-eps-beneficiario')"
            ></v-autocomplete>
            <div v-else class="skeleton-loader"></div>
          </v-col>
          <!-- FIN EPS -->

          <!-- REGIMEN -->
          <v-col cols="12" lg="3" class="mb-n8">
            <label for=""
              >Regimen al que pertenece<span class="campo_obligatorio"
                >*</span
              ></label
            >
            <v-select
              outlined
              v-if="!cargando_regimenes"
              v-model="beneficiario.regimen"
              :menu-props="{ bottom: true, offsetY: true }"
              :items="regimenes"
              item-text="regimen"
              item-value="id"
              :rules="[(v) => !!v || 'Campo requerido']"
              dense
              :="false"
              v-on:change="activarModalRegimen()"
            ></v-select>
            <div v-else class="skeleton-loader"></div>
          </v-col>
          <!-- FIN REGIMEN -->

          <!-- PARENTESCO -->
          <v-col cols="12" lg="3" class="mb-n8">
            <label for=""
              >Parentesco<span class="campo_obligatorio">*</span></label
            >
            <v-select
              v-if="!cargando_items_parentescos"
              outlined
              :items="items_parentesco"
              v-model="beneficiario.parentesco"
              item-text="parentesco"
              item-value="ID_Paren"
              :menu-props="{ bottom: true, offsetY: true }"
              dense
              :rules="[(v) => !!v || 'Campo requerido']"
            ></v-select>
            <div v-else class="skeleton-loader"></div>
          </v-col>
          <!-- FIN PARENTESCO -->

          <!-- MAIL -->
          <v-col cols="12" lg="6" class="mb-n8">
            <label for="">Correo</label>
            <v-text-field
              outlined
              dense
              :rules="[
                (v) =>
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                  'E-mail debe ser valido',
              ]"
              v-model="beneficiario.correo"
            ></v-text-field>
          </v-col>
          <!-- FIN MAIL -->

          <!-- DIRECCION -->
          <v-col cols="12" lg="6" class="mb-n8">
            <label for=""
              >Dirección<span class="campo_obligatorio">*</span></label
            >
            <v-text-field
              outlined
              dense
              readonly
              v-model="beneficiario.direccion_completa"
              append-icon="mdi-map-marker"
              @click="modal_direccion_beneficiario = true"
              @click:append="modal_direccion_beneficiario = true"
              :rules="[(v) => !!v || 'Campo requerido']"
            ></v-text-field>
          </v-col>
          <!-- FIN DIRECCION -->
        </v-row>

        <v-row>
          <v-col class="mt-3" cols="12">
            <span class="mt-3 ml-4" style="color: #000; font-weight: 900"
              >Asegurabilidad y Documentos</span
            >
          </v-col>

          <!-- ASEGURABILIDAD -->
          <v-col cols="12" lg="6">
            <label for=""
              >Asegurabilidad<span class="campo_obligatorio">*</span></label
            >
            <v-row class="pa-0 ma-0">
              <v-text-field
                v-if="!cargando_enfermedades_asegurabilidad"
                v-model="beneficiario.asegurabilidad_texto"
                append-icon="mdi-clipboard-arrow-up"
                @click:append="abrirModalAsegurabilidad('beneficiario')"
                @click="abrirModalAsegurabilidad('beneficiario')"
                :rules="[(v) => !!v.length || 'Campo Requerido']"
                placeholder="Indicar Asegurabilidad"
                dense
                outlined
                readonly
              ></v-text-field>
              <div v-else class="skeleton-loader"></div>
            </v-row>
          </v-col>
          <!-- FIN ASEGURABILIDAD -->

          <!-- ANEXAR DOCUMENTOS -->
          <v-col cols="12" lg="6">
            <label for=""
              >Anexar Documentos<span class="campo_obligatorio">*</span></label
            >
            <v-file-input
              label="Subir Archivo"
              multiple
              chips
              v-model="beneficiario.anexos"
              append-icon="mdi-paperclip"
              prepend-icon=""
              outlined
              dense
              color="primary"
            ></v-file-input>
            <span class="mt-3 ml-4" style="color: #000; font-weight: 900"
              >Agregar Anexo en los siguientes casos</span
            >
            <span>
              <br />
              Certificado de eps si perteneces a un regimen especial ser mayor
              de 70 años</span
            >
          </v-col>
          <!-- FIN ANEXAR DOCUMENTOS -->
        </v-row>

        <v-card-actions v-if="mostrar_formulario_agregar_beneficiario">
          <v-col>
            <v-btn
              class="primary"
              style="font-size: 12px"
              :disabled="!validarFormularioAgregarBeneficiario"
              @click="agregarBeneficiario()"
              >{{ editar_beneficiario ? "Guardar" : "Agregar Usuario" }}</v-btn
            >
            <v-btn
              class="red ml-3"
              style="font-size: 12px"
              dark
              @click="cancelarAgregarBeneficiario()"
              >Cancelar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-form>
    </div>
    <!-- FIN FORMULARIO AGREGAR BENEFICIARIO -->

    <v-dialog v-model="modal_direccion_beneficiario" width="900">
      <ModalDireccion
        v-if="modal_direccion_beneficiario"
        :json_direccion="beneficiario.direccion"
        @guardar_direccion="guardarDireccionBeneficiario"
        @cerrar="modal_direccion_beneficiario = false"
      />
    </v-dialog>
  </v-card>
</template>
<script>
import afiliacionService from "../services/afiliacionService";
import ModalDireccion from "../../general/components/ModalDireccion.vue";
import beneficiarioService from "@/modules/beneficiarios/services/beneficiarioService";
export default {
  props: {
    beneficiario: {
      type: Object,
      required: true,
    },
    beneficiarios: {},
    editar_beneficiario: { type: Boolean },
    codigo_autorizacion: {},
  },
  data: () => ({
    //-----------INPUTS FORMULARIO ----------------

    //NOTA: Variables con seudo nombre "cargando" quieren decir que esta variables activan los skeleton-loaders de los campos  mientras se traen los datos de db

    validando_documento_beneficiario: false,

    tipos_documento: [],

    cargando_tipos_documento: false,
    tipos_documento: [],

    cargando_generos: false,
    generos: [],

    cargando_items_eps: false,
    items_eps: [],

    cargando_regimenes: false,
    regimenes: [],

    cargando_items_parentescos: false,
    items_parentesco: [],

    cargando_enfermedades_asegurabilidad: false,
    enfermedades_asegurabilidad: [],

    picker_fecha_nacimiento: false,

    mostrar_datos_basicos_agregar_beneficiario: true,

    mostrar_formulario_agregar_beneficiario: false,

    agregar_nuevo_beneficiario_o_pagar: false,

    campo_desactivado: false,

    //--------- MODALES -------------
    modal_direccion_beneficiario: false,
  }),
  components: {
    ModalDireccion,
  },
  created() {
    this.cargarDatosVista();

    if (this.editar_beneficiario) {
      this.habilitarEdicionBeneficiario();
    } else {
      if (this.beneficiarios.length > 0) {
        //Se esconden los datos del beneficiario
        this.mostrar_datos_basicos_agregar_beneficiario = false;
        this.mostrar_formulario_agregar_beneficiario = false;

        //Se muestran los dos botones para continuar con el pago o agregar otro beneficiario
        this.agregar_nuevo_beneficiario_o_pagar = true;
      }
    }
  },
  watch: {
    editar_beneficiario: function (nuevo_valor) {
      if (nuevo_valor) {
        this.habilitarEdicionBeneficiario();
      }
    },
  },
  computed: {
    /**Funcion que valida los datos basicos del beneficiario( tipo_documento, numero_documento) */
    validarDocumentoBeneficiario() {
      if (
        this.beneficiario.tipo_documento != null &&
        this.beneficiario.numero_documento != null
      ) {
        return true;
      }
    },
    /**Funcion que valida que el regimen no sea 2 (Subsidiado)*/
    validarFormularioAgregarBeneficiario() {
      if (this.beneficiario.regimen != 2) {
        return true;
      }
    },
  },
  methods: {
    /**Funcion que agrega nuevo beneficiario */
    agregarBeneficiario() {
      //Se valida que todas los inputs cumplan con los requisitos que se les puso
      if (this.$refs.formularioBeneficiario.validate()) {
        //Se valida de nuevo que el regimen no sea 2(Subsidiado) por seguridad
        if (!this.validarBeneficarioExistente()) {
          if (this.beneficiario.regimen != 2) {
            if (!this.editar_beneficiario) {
              //Se emite el evento de guardar el beneficiario al componente padre AfiliacionFamiliar.vue
              this.$emit("guardar-beneficiario");
            } else {
              this.$emit("actualizar-beneficiario");
            }

            //Se esconden los datos del beneficiario
            this.mostrar_datos_basicos_agregar_beneficiario = false;
            this.mostrar_formulario_agregar_beneficiario = false;

            //Se muestran los dos botones para continuar con el pago o agregar otro beneficiario
            this.agregar_nuevo_beneficiario_o_pagar = true;

            //Se reiniciar los datos del formulario del beneficiario
            this.$emit("reiniciar-datos-beneficiario");
          } else {
            //Se emite el evento de validar-regimen que advierte que el regimen no puede ser subsidiado segun las normas de la empresa
            this.$emit(
              "activar-modal-informativo",
              "validar-regimen-beneficiario"
            );
          }
        } else {
          this.$swal.fire({
            title:
              "El Usuario con el tipo y numero de documento ingresados ya esta registrado",
            icon: "info",
            allowEscapeKey: false,
            allowOutsideClick: false,
            allowEnterKey: false,
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
        }
      }
    },

    /**Funcion que emite el evento para guardar la direccion del titular
     * @param {object} direccion direccion a guardar
     */
    guardarDireccionBeneficiario(direccion) {
      this.$emit("guardar-direccion-beneficiario", direccion);
    },

    /**Funcion que habilita todos los campos para editar un usuario agregado */
    habilitarEdicionBeneficiario() {
      //Se desactivan los campos del tipo de documento y el numero de documento
      this.campo_desactivado = true;

      //Se muestran los campos de los datos basicos del beneficiario (tipo documento,numero documento)
      this.mostrar_datos_basicos_agregar_beneficiario = true;

      //Se muestra el formulario completo para agregar el beneficiario
      this.mostrar_formulario_agregar_beneficiario = true;

      //Se desactiva la vista de los botonos de agregar nuevo beneficiario o pasar a pagar
      this.agregar_nuevo_beneficiario_o_pagar = false;
    },

    /**Funcion que valida los datos basicos del beneficiario */
    async validarDatosBasicosBeneficiario() {
      let puedeAfiliarse = true;
      this.validando_documento_beneficiario = true;
      //Se valida que el beneficiario ya no haya sido agregado
      if (!this.validarBeneficarioExistente()) {
        if (this.codigo_autorizacion.id_tipo_codigo != 1) {
          await beneficiarioService
            .consultarBeneficiarioRmv(this.beneficiario.numero_documento)
            .then(async (response) => {
              if (response.data.length > 0) {
                let beneficiarioValidar = response.data;

                //Si no tiene ninguna lista blanca, Whitelist, validar restriscciones
                if (
                  beneficiarioValidar[0].benwhite != 1 &&
                  beneficiarioValidar[0].titwhite != 1 &&
                  beneficiarioValidar[0].gruwhite != 1
                ) {
                  //revisar Si tiene blacklist en la tabla beneficiario
                  if (beneficiarioValidar[0].benblack == 1) {
                    //No permitir su afiliacion
                    puedeAfiliarse = false;
                  }
                  //revisar Si tiene blacklist en la tabla titulares_afi
                  if (beneficiarioValidar[0].titblack == 1) {
                    //No permitir su afiliacion
                    puedeAfiliarse = false;
                  }
                  //revisar Si tiene blacklist en la tabla grupos_afi
                  if (beneficiarioValidar[0].grublack == 1) {
                    //No permitir su afiliacion
                    puedeAfiliarse = false;
                  }
                  //Si el estado bencba es 0, 6 ó 13, no se permite la afiliación.
                  if (
                    beneficiarioValidar[0].bencba == 0 ||
                    beneficiarioValidar[0].bencba == 6 ||
                    beneficiarioValidar[0].bencba == 13
                  ) {
                    //No permitir su afiliacion
                    puedeAfiliarse = false;
                  }

                  //Consultar la prioridad del beneficiario
                  await this.getPrioridadBen(
                    parseInt(this.beneficiario.numero_documento)
                  );

                  if (
                    this.beneficiario.prioridad == "4" ||
                    this.beneficiario.prioridad == "5"
                  ) {
                    //No permitir su afiliacion
                    puedeAfiliarse = false;
                    //debugger
                  }

                  let beneficiarios_titular =
                    await beneficiarioService.consultarEstadoCarteraBenRmv(
                      response.data[0].ID_Titular
                    );

                  if (beneficiarios_titular.data.length > 0) {
                    beneficiarios_titular.data.forEach((beneficiario) => {
                      //Se valida tambien el estado en cartera de cada beneficiario con el campo bencba en el caso que deba algo
                      // 6 (Falta de Pago) tabla tipo_bajas db medisoft
                      // 13 (Deuda) tabla tipo_bajas db medisoft
                      if (
                        beneficiario.bencba == 6 ||
                        beneficiario.bencba == 13
                      ) {
                        puede_afiliarse = false;
                      }
                    });
                  }
                }
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal.fire({
                title: "Error al validar el documento",
                icon: "error",
                allowEscapeKey: false,
                allowOutsideClick: false,
                allowEnterKey: false,
                position: "center",
                showConfirmButton: true,
                confirmButtonText: "OK",
              });
              this.validando_documento_beneficiario = false;
              return;
            });
        }

        if (!puedeAfiliarse) {
          this.$emit("activar-modal-no-posible-afiliacion", true);

          this.validando_documento_beneficiario = false;
          return;
        }
        //Se muestra el formulario completo para agregar el beneficiario
        this.mostrar_formulario_agregar_beneficiario = true;

        //Se desactiva los campos de los datos basicos del beneficiario
        this.campo_desactivado = true;
      } else {
        this.$swal.fire({
          title:
            "El Usuario con el tipo y numero de documento ingresados ya esta registrado",
          icon: "info",
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          position: "center",
          showConfirmButton: true,
          confirmButtonText: "OK",
        });
      }

      this.validando_documento_beneficiario = false;
    },

    /**
     * Funcion que determina la prioridad de un beneficiario
     * @param {number} numero_documento numero de documento del beneficiario a consultar la prioridad
     */
    async getPrioridadBeneficiario(numero_documento) {
      await beneficiarioService
        .consultarTotalServicios(numero_documento)
        .then(async (response) => {
          if (response.data.length > 0) {
            let benTotalServicios = response.data;

            await beneficiarioService
              .consultarMesesBen(benTotalServicios[0].ID_Ben)
              .then((response) => {
                if (response.data.length > 0) {
                  let meses = response.data;
                  //PS
                  benTotalServicios[0].ps = (
                    (benTotalServicios[0].total_servicios * 100) /
                    (0.25 * meses.meses)
                  ).toFixed(1);
                  console.log(
                    benTotalServicios[0].total_servicios,
                    meses.meses
                  );
                  //debugger

                  let prioridad;
                  //Si tiene estado ApoyoVip 1 en la base de datos tendrá prioridad VIP
                  if (benTotalServicios[0].ApoyoVip == 1) {
                    prioridad = "VIP";
                  } else if (benTotalServicios[0].ID_Titular == 7477) {
                    //La asociación viva cerritos se toma como prioridad APE
                    prioridad = "APE";
                  } else if (benTotalServicios[0].bencba == 15) {
                    //Las cortesías tienen prioridad COR
                    prioridad = "COR";
                  } else {
                    //Si el beneficiario solo tiene un servicio y lleva menos de 6 meses
                    //Siempre será prioridad 2
                    if (
                      benTotalServicios[0].total_servicios == "1" &&
                      meses.meses <= 6
                    ) {
                      prioridad = "2";
                    } else {
                      //Dependiendo el promedio del contrato
                      //se determina la prioridad del servicio
                      if (
                        parseInt(benTotalServicios[0].ps) <
                        parseInt(this.prioridades[0].rango_final)
                      ) {
                        prioridad = this.prioridades[0].prioridad;
                      } else if (
                        parseInt(benTotalServicios[0].ps) <
                        parseInt(this.prioridades[1].rango_final)
                      ) {
                        prioridad = this.prioridades[1].prioridad;
                      } else if (
                        parseInt(benTotalServicios[0].ps) <
                        parseInt(this.prioridades[2].rango_final)
                      ) {
                        prioridad = this.prioridades[2].prioridad;
                      } else if (
                        parseInt(benTotalServicios[0].ps) <
                        parseInt(this.prioridades[3].rango_final)
                      ) {
                        prioridad = this.prioridades[3].prioridad;
                      } else if (
                        parseInt(benTotalServicios[0].ps) <
                        parseInt(this.prioridades[4].rango_final)
                      ) {
                        prioridad = this.prioridades[4].prioridad;
                      } else {
                        prioridad = "5";
                      }
                      //debugger
                    }
                  }
                  //Se asigna la prioridad en la variable global del modulo
                  this.$emit("cambiar-prioridad-beneficiario", prioridad);
                }
              });
          }
        });
    },

    /**Funcion que valida si el beneficiario ingresado en el formulario ya existe */
    validarBeneficarioExistente() {
      let beneficiario_existente = false;

      if (this.beneficiarios.length > 0) {
        //Se itera sobre todos los beneficiarios agregados para saber si existe
        this.beneficiarios.forEach((beneficiario) => {
          //En el caso de que coincida el tipo de documento y el numero de documento se declara que el beneficiario si existe en la variable
          if (
            beneficiario.tipo_documento == this.beneficiario.tipo_documento &&
            beneficiario.numero_documento == this.beneficiario.numero_documento
          ) {
            beneficiario_existente = true;
          }
        });
      }

      //En el caso de que se este editando al beneficiario se salta esta validacion y retornamos que este no existe
      if (this.editar_beneficiario) {
        beneficiario_existente = false;
      }

      return beneficiario_existente;
    },

    /**Funcion que cancela el agregar o editar el beneficiario */
    cancelarAgregarBeneficiario() {
      this.$swal
        .fire({
          title: "Estas seguro de cancelar?",
          text: "Se perderan los datos no guardados",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Confirmar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            //Se valida que no se este editando un beneficiario
            if (!this.editar_beneficiario) {
              //Se valida si ya hay beneficiarios agregados
              if (this.beneficiarios.length > 0) {
                //Se esconden los datos del beneficiario
                this.mostrar_datos_basicos_agregar_beneficiario = false;
                this.mostrar_formulario_agregar_beneficiario = false;

                //Se muestran los dos botones para continuar con el pago o agregar otro beneficiario
                this.agregar_nuevo_beneficiario_o_pagar = true;
              } else {
                //En el caso de que no hayan beneficiarios ya agregados se muestran los datos basicos del beneficiario
                this.campo_desactivado = false;
                this.mostrar_formulario_agregar_beneficiario = false;

                //Se reinicia los datos del formulario del beneficiario
                this.$emit("reiniciar-datos-beneficiario");
              }
            } else {
              //No se evalua si hay beneficiarios ya que si esta editando se supone que ya hay al menos 1

              //Se emite la actualizacion del beneficiario como si nada
              // this.$emit("actualizar-beneficiario");
              this.$emit("cambiar-editando-beneficiario");

              //Se esconden los datos del beneficiario
              this.mostrar_datos_basicos_agregar_beneficiario = false;
              this.mostrar_formulario_agregar_beneficiario = false;

              //Se muestran los dos botones para continuar con el pago o agregar otro beneficiario
              this.agregar_nuevo_beneficiario_o_pagar = true;
            }
          }
        });
    },

    /** Funcion que valida el evento de eliminar beneficiario*/
    validarUsuarioEliminado(tipo_documento, numero_documento) {
      //solo se ejecuta SI se esta en el formulario del beneficiario especificamente al momento de ejecutar el evento de eliminar beneficiario

      //Se valida si se esta editando en ese momento
      if (this.editar_beneficiario) {
        //Se valida si los datos del beneficiario eliminado coiciden con el que se esta editando
        if (
          this.beneficiario.tipo_documento == tipo_documento &&
          this.beneficiario.numero_documento == numero_documento
        ) {
          //Se valida si no hay mas beneficiarios
          //(A pesar de que ya se haya eliminado en el componente padre, este componente hijo no detecta el cambio asi que si toca validar que solo se uno)
          if (this.beneficiarios.length == 1) {
            //Se emite el evento para poder reiniciar los datos del beneficiario
            this.$emit("reiniciar-datos-beneficiario");

            //Se muestran los datos basicos del beneficiario
            this.mostrar_datos_basicos_agregar_beneficiario = true;
            this.campo_desactivado = false;

            //Se esconden los datos del formulario completo asi mismo como los botones para agregar o pasar a pagar
            this.mostrar_formulario_agregar_beneficiario = false;
            this.agregar_nuevo_beneficiario_o_pagar = false;
          } else {
            //En el caso de si hayan mas beneficarios agregados

            //Se emite el evento para poder reiniciar los datos del beneficiario
            this.$emit("reiniciar-datos-beneficiario");

            //Se esconden los datos basicos del beneficiario
            this.mostrar_datos_basicos_agregar_beneficiario = false;
            this.campo_desactivado = false;

            //Se esconden los datos del formulario completo del beneficiario
            this.mostrar_formulario_agregar_beneficiario = false;

            //Se muestran los botones de agregar un nuevo beneficiario o pasar a pagar
            this.agregar_nuevo_beneficiario_o_pagar = true;
          }
        }
        this.$emit("cambiar-editando-beneficiario");
      } else {
        //En el caso de que no este editando

        //Se valida si en este momento esta en los botones de agregar un nuevo beneficiario o pasar a pagar
        if (this.agregar_nuevo_beneficiario_o_pagar) {
          //Se valida si no hay mas beneficiarios
          //(A pesar de que ya se haya eliminado en el componente padre, este componente hijo no detecta el cambio asi que si toca validar que solo se uno)
          if (this.beneficiarios.length == 1) {
            //Se emite el evento para poder reiniciar los datos del beneficiario
            this.$emit("reiniciar-datos-beneficiario");

            //Se muestran los datos basicos del beneficiario
            this.mostrar_datos_basicos_agregar_beneficiario = true;
            this.campo_desactivado = false;

            //Se esconden los datos del formulario completo asi mismo como los botones para agregar o pasar a pagar
            this.mostrar_formulario_agregar_beneficiario = false;
            this.agregar_nuevo_beneficiario_o_pagar = false;
          }
        }
      }
    },

    /**
     * Funcion que retorna la edad de una persona apartir de su fecha de nacimiento
     * @param {string} fecha_nacimiento la fecha de nacimiento a la que se le va calcular la edad
     * @return {number} la edad de la persona
     */
    calcularEdad(fecha_nacimiento) {
      //Se obtiene la fecha actual en formato Date https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/Date
      var hoy = new Date();

      //Se obtiene la fecha en formato Date
      var cumpleanos = new Date(fecha_nacimiento);

      //Al hacer pruebas se observo que retornaba la fecha con un dia menos por lo que se le agrega un dia a la fecha obtenida
      cumpleanos.setDate(cumpleanos.getDate() + 1);

      //Se obtiene en primera estancia la edad restanto el año actual con el año de la fecha recibida por parametros
      var edad = hoy.getFullYear() - cumpleanos.getFullYear();

      //Se resta el mes actual y el mes de la fecha de cumpleaños para determinar si este ya paso
      var m = hoy.getMonth() - cumpleanos.getMonth();

      //Se valida que si el mes de la fecha de cumpleaños no ha pasado se le reste a la edad 1
      //O
      //En el caso de que el mes sea igual al actual( m == 0) y el dia de actual sea menor al de la fecha recibida se le resta a la edad 1 tambien
      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
      }

      //Se retorna la edad
      return edad;
    },

    /**Funcion que emite el evento en el componente padre para activar el modal informativo cada que se cambia de eps */
    activarModalInformativo(titulo) {
      this.$emit("activar-modal-informativo", titulo);
    },
    /**Funcion que se ejecuta cada vez que se cambia el regimen del titular y valida que el regimen no sea 2 subsidiado */
    activarModalRegimen() {
      if (this.beneficiario.regimen == 2) {
        this.$emit("activar-modal-informativo", "validar-regimen-beneficiario");
      }
    },
    abrirModalDireccion() {},

    /**Funcion que emite el evento en el componente padre para abrir el modal asegurabilidad con todas las enfermedades que le pertenece*/
    abrirModalAsegurabilidad(titulo) {
      //Se emite el evento
      this.$emit(
        "abrir-modal-asegurabilidad",
        titulo,
        this.enfermedades_asegurabilidad
      );
    },

    /**Funcion que emite el evento para cambiar de pestaña*/
    pago() {
      this.$emit("pago");
    },

    /**Funcion que reinicia los datos del beneficiario y permite agregar uno nuevo */
    agregarOtroBeneficiario() {
      this.$emit("cambiar-editando-beneficiario");
      //Se emite el evento para poder reiniciar los datos del beneficiario
      this.$emit("reiniciar-datos-beneficiario");

      this.$refs.formularioBeneficiario.resetValidation();

      //Se muestran los datos basicos del beneficiario
      this.mostrar_datos_basicos_agregar_beneficiario = true;
      this.campo_desactivado = false;

      //Se esconden los datos del formulario completo asi mismo como los botones para agregar o pasar a pagar
      this.agregar_nuevo_beneficiario_o_pagar = false;
      this.mostrar_formulario_agregar_beneficiario = false;
    },

    /**Funcion que trae todos los datos de la vista de manera asincrona */
    cargarDatosVista() {
      //SE TRAEN LOS TIPOS DE DOCUMENTO
      this.cargando_tipos_documento = true;
      afiliacionService
        .getTiposDocumentos()
        .then((response) => {
          if (response.data.length) {
            response.data.forEach((tipo_documento) => {
              if (tipo_documento.idTipoDoc != 2) {
                this.tipos_documento.push(tipo_documento);
              }
            });
          }

          this.cargando_tipos_documento = false;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer los tipos de documentos",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_tipos_documento = false;
        });

      //SE TRAEN LOS GENEROS
      this.cargando_generos = true;
      afiliacionService
        .getGeneros()
        .then((response) => {
          this.generos = response.data;
          this.cargando_generos = false;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer los generos",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_generos = false;
        });

      //SE TRAE LOS REGIMENES
      this.cargando_regimenes = true;
      afiliacionService
        .getRegimenes()
        .then((response) => {
          this.cargando_regimenes = false;
          this.regimenes = response.data;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer los regimenes",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_regimenes = false;
        });

      //SE TRAE LOS PARENTESCOS
      this.cargando_items_parentescos = true;
      afiliacionService
        .getParentescos()
        .then((response) => {
          this.items_parentesco = response.data;

          this.cargando_items_parentescos = false;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer los tipos de parentescos",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_items_parentescos = false;
        });

      //SE TREA LAS EPS
      this.cargando_items_eps = true;
      afiliacionService
        .getEps()
        .then((response) => {
          this.items_eps = response.data;
          this.cargando_items_eps = false;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer las eps",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_items_eps = false;
        });

      //SE TRAE LAS ENFERMEDADADES DE ASEGURABILIDAD EN DB
      this.cargando_enfermedades_asegurabilidad = true;
      afiliacionService
        .getEnfermedadesAsegurabilidad()
        .then((response) => {
          this.enfermedades_asegurabilidad = response.data;
          this.cargando_enfermedades_asegurabilidad = false;
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error al traer las enfermedades asegurabilidad",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          console.log(error);
          this.cargando_enfermedades_asegurabilidad = false;
        });
    },
  },
};
</script>
<style scoped>
.skeleton-loader {
  border-radius: 3px;
  width: 100%;
  height: 39px;
  display: block;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}
</style>
