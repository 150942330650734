<template>
  <v-card>
    <v-toolbar dark height="40" color="indigo darken-1">
      <v-spacer></v-spacer>
      <v-toolbar-title class="mr-n12">Codigo Confirmacion</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        dark
        class="white--text"
        small
        color="red"
        :disabled="enviando_codigo || validando_codigo"
        @click="$emit('cerrar-modal')"
      >
        X
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <div v-if="medio_confirmacion_seleccionado">
        <v-row class="mt-2">
          <v-col >
            <h2 style="text-align: center;" class="mb-4">Ingresa el codigo que te enviamos por {{ medio_confirmacion }}</h2>
            <label 
              >Lo enviamos a
              <span v-if="medio_confirmacion == 'telefono'" 
                >el numero de telefono
                <a @click="abrirModalModificarDatosMedioConfirmacion">{{
                  telefono
                }}</a
                >. Si no lo encuentras la pestaña de spam</span
              >
              <span v-else>
                <a @click="abrirModalModificarDatosMedioConfirmacion">
                  {{ correo }}</a
                >. Si no lo encuentras revisa el correo no deseado
              </span>
           
            </label>

            <!-- CODIGO DE CONFIRMACION -->
            <div>
              <div class="ma-auto" style="max-width: 300px; position: relative">
                <v-otp-input
                  class="mt-2"
                  :disabled="enviando_codigo"
                  length="4"
                  v-model="codigo_confirmacion"
                  @finish="validarCodigo"
                ></v-otp-input>
                <v-overlay absolute :value="validando_codigo">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-overlay>
              </div>
            </div>
            <!-- FIN  CODIGO DE CONFIRMACION -->
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-4">
            <!-- <v-btn
              text
              plain
              color="primary"
              :disabled="
                cargando_volver_enviar || enviando_codigo || validando_codigo
              "
              small
              v-if="medio_confirmacion == 'correo'"
              @click="(medio_confirmacion = 'telefono'), reEnviarCodigo"
              >Enviar por Sms</v-btn
            >
            <v-btn
              text
              plain
              :disabled="
                cargando_volver_enviar || enviando_codigo || validando_codigo
              "
              color="primary"
              small
              v-if="medio_confirmacion == 'telefono'"
              @click="(medio_confirmacion = 'correo'), reEnviarCodigo"
              >Enviar por correo</v-btn
            > -->

            <v-btn
              text
              plain
              :disabled="enviando_codigo || validando_codigo"
              color="primary"
              small
              @click="medio_confirmacion_seleccionado = false"
              >Cambiar metodo</v-btn
            >
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn
              v-if="medio_confirmacion == 'telefono'"
              color="success"
              :loading="enviando_codigo"
              :disabled="cargando_volver_enviar || validando_codigo"
              @click="reEnviarCodigo"
              >Enviar de nuevo<span
                class="ml-1"
                v-if="cargando_volver_enviar"
                >{{ contadorMostrar }}</span
              ></v-btn
            >
            <v-btn
              v-else
              color="success"
              :loading="enviando_codigo"
              :disabled="cargando_volver_enviar_correo || validando_codigo"
              @click="reEnviarCodigo"
              >Enviar de nuevo<span
                class="ml-1"
                v-if="cargando_volver_enviar_correo"
                >{{ contadorMostrarCorreo }}</span
              ></v-btn
            >
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <div class="d-flex justify-center">
          <v-btn style="cursor: default" fab large color="primary" class="mt-4"
            ><v-icon large>mdi-message-processing</v-icon></v-btn
          >
        </div>
        <div class="d-flex justify-center mt-2">
          <label>¿Como deseas obtener el código?</label>
        </div>
        <v-row class="mt-2" justify="center">
          <v-col cols="10">
            <v-btn
              color="primary"
              class="text--white"
              block
              :disabled="cargando_volver_enviar || validando_codigo"
              @click="medioConfirmacionSeleccionado('telefono')"
              ><v-icon class="mr-2">mdi-cellphone-message</v-icon>Enviar por
              SMS<span class="ml-1" v-if="cargando_volver_enviar">{{
                contadorMostrar
              }}</span></v-btn
            >
          </v-col>
          <v-col cols="10" class="mt-n3">
            <v-btn
              color="primary"
              class="text--white"
              block
              :disabled="cargando_volver_enviar_correo || validando_codigo"
              @click="medioConfirmacionSeleccionado('correo')"
              ><v-icon class="mr-2">mdi-email-fast</v-icon>Enviar por
              correo<span class="ml-1" v-if="cargando_volver_enviar_correo">{{
                contadorMostrarCorreo
              }}</span></v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-card-text>

    <!-- MODAL MODIFICAR MEDIO DE CONFIRMACION -->
    <v-dialog v-model="modal_modificar_medio_confirmacion" width="450px">
      <v-card>
        <v-toolbar dark height="40" color="indigo darken-1">
          <v-spacer></v-spacer>
          <v-toolbar-title class="mr-n12"
            >Modificar {{ medio_confirmacion }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            dark
            class="white--text"
            small
            color="red"
            @click="modal_modificar_medio_confirmacion = false"
          >
            X
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="formularioMedioConfirmacion">
            <v-row class="mt-2">
              <v-col cols="12">
                <label>Nuevo {{ medio_confirmacion }}</label>

                <v-text-field
                  v-if="medio_confirmacion == 'telefono'"
                  type="number"
                  outlined
                  dense
                  v-model="celular_modificado"
                  :rules="[
                    (v) => !!v || 'Telefono requerido',
                    (v) =>
                      v >= 1000000000 || 'Tiene que tener al menos 10 digitos',
                  ]"
                ></v-text-field>
                <v-text-field
                  v-else
                  outlined
                  dense
                  v-model="correo_modificado"
                  :rules="[
                    (v) =>
                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                      'E-mail debe ser valido',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-row class="justify-end">
            <v-btn
              class="primary mt-n3 mb-2"
              @click="modificarMedioConfirmacion"
              >Modificar</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--FIN  MODAL MODIFICAR MEDIO DE CONFIRMACION -->
  </v-card>
</template>
<script>
import tokenService from "@/modules/general/services/tokenService";
import mailApiService from "@/modules/general/services/mailApiService.js";

export default {
  props: ["telefono", "correo"],
  data: () => ({
    medio_confirmacion_seleccionado: false,

    cargando_volver_enviar: false,
    cargando_volver_enviar_correo: false,

    contador: 59,
    contador_correo: 59,
    codigo_confirmacion: "",
    medio_confirmacion: "telefono",

    enviando_codigo: false,
    validando_codigo: false,

    //-------  MODALES -----
    modal_modificar_medio_confirmacion: false,
    celular_modificado: null,
    correo_modificado: null,
  }),
  async created() {
    //Al cargar el componente se va enviar directamente el codigo al numero de telefono
    this.enviando_codigo = true;
    // await tokenService
    //   .enviarMensajeConToken({
    //     codigo: "",
    //     celular: this.telefono,
    //     correo: this.correo,
    //     medio_confirmacion: this.medio_confirmacion,
    //   })
    //   .then((response) => {
    //     this.empezarContadorEnviarDeNuevo();
    //   });
    this.enviando_codigo = false;
  },
  computed: {
    /**Metodo que va retornar el texto del contador al boton */
    contadorMostrar() {
      if (this.contador >= 10) {
        return "00:" + this.contador;
      } else {
        return "00:0" + this.contador;
      }
    },
    contadorMostrarCorreo() {
      if (this.contador_correo >= 10) {
        return "00:" + this.contador_correo;
      } else {
        return "00:0" + this.contador_correo;
      }
    },
  },
  methods: {
    /**Funcion que valida directamente el codigo */
    async validarCodigo() {
      this.validando_codigo = true;

      //Se valida que si este lleno
      if (this.codigo_confirmacion.length == 4) {
        //Se valida el medio de confirmacion
        if (this.medio_confirmacion == "telefono") {
          //Se llama a la api local del proyecto
          await tokenService
            .validarCodigoSms(this.codigo_confirmacion, this.telefono)
            .then((response) => {
              this.$swal
                .fire({
                  title: "Codigo confirmado",
                  icon: "success",
                  position: "center",
                  timer: 2000,
                  showConfirmButton: false,
                })
                .then(() => {
                  this.$emit("codigo-confirmado");
                  this.$emit("cerrar-modal");
                });
            })
            .catch((error) => {
              console.log(error);
              this.$swal.fire({
                title: "El codigo ingresado no coincide con el enviado",
                icon: "info",
                position: "center",
                timer: 2000,
                showConfirmButton: false,
              });
            });
        } else {
          //Se llama a la api externa para validar el codigo
          await mailApiService
            .validateCode(this.codigo_confirmacion, this.correo)
            .then((response) => {
              this.$swal
                .fire({
                  title: "Codigo confirmado",
                  icon: "success",
                  position: "center",
                  timer: 2000,
                  showConfirmButton: false,
                })
                .then(() => {
                  this.$emit("codigo-confirmado");
                  this.$emit("cerrar-modal");
                });
            })
            .catch((error) => {
              console.log(error);
              this.$swal.fire({
                title: "El codigo ingresado no coincide con el enviado",
                icon: "info",
                position: "center",
                timer: 2000,
                showConfirmButton: false,
              });
            });
        }
      }
      this.validando_codigo = false;
    },
    medioConfirmacionSeleccionado(medio_confirmacion) {
      this.medio_confirmacion = medio_confirmacion;
      this.medio_confirmacion_seleccionado = true;
      this.reEnviarCodigo();
    },
    /**Funcion que activa el evento para modificar el medio de confirmacion */
    modificarMedioConfirmacion() {
      //Se valida que si cumpla con las reglas de cada input
      if (this.$refs.formularioMedioConfirmacion.validate()) {
        //Se emite el evento para cambiar el medio de confirmacion
        this.$emit("cambiar-medio-confirmacion", {
          medio_confirmacion: this.medio_confirmacion,
          celular_modificado: this.celular_modificado,
          correo_modificado: this.correo_modificado,
        });

        this.modal_modificar_medio_confirmacion = false;
      }
    },

    /**Metodo que abre el modal para modificar el medio de confirmacion */
    abrirModalModificarDatosMedioConfirmacion() {
      //Se valida que no se este enviando el codigo o se este validando del mismo
      if (!this.validando_codigo && !this.enviando_codigo) {
        this.correo_modificado = this.correo;
        this.celular_modificado = this.telefono;
        this.modal_modificar_medio_confirmacion = true;
      }
    },

    /**Funcion que re envia el codigo dependiendo del medio de confirmacion */
    async reEnviarCodigo() {
      this.enviando_codigo = true;

      //Se limpia el valor del codigo confirmacion anterior
      this.codigo_confirmacion = "";

      //Se valida que no se este esperando todavia para volve a enviar el codigo
      if (!this.cargando_volver_enviar) {
        //Se valida el medio de confirmacion
        if (this.medio_confirmacion == "telefono") {
          //Se llama a la api local de la app para que genere y envie el codigo por medio de sms
          await tokenService
            .enviarMensajeConToken({
              codigo: "",
              celular: this.telefono,
              correo: this.correo,
              medio_confirmacion: this.medio_confirmacion,
            })
            .then((response) => {
              this.$swal.fire({
                title: "Codigo Enviado",
                icon: "success",
                position: "center",
                timer: 2000,
                showConfirmButton: false,
              });
            })
            .catch((error) => {
              console.log(error);
              this.$swal.fire({
                title: "Error al enviar el mensaje intente mas tarde",
                icon: "error",
                position: "center",
                timer: 2000,
                showConfirmButton: false,
              });
            });
        } else {
          //Se llama a la api externa para que genere y envie el codigo por medio de correo
          await mailApiService
            .generateCode({ correo: this.correo })
            .then((response) => {
              this.$swal.fire({
                title: "Codigo Enviado",
                icon: "success",
                position: "center",
                timer: 2000,
                showConfirmButton: false,
              });
            })
            .catch((error) => {
              console.log(error);
              this.$swal.fire({
                title: "Error al enviar el mensaje intente mas tarde",
                icon: "error",
                position: "center",
                timer: 2000,
                showConfirmButton: false,
              });
            });
        }
      }
      this.enviando_codigo = false;

      if (this.medio_confirmacion == "telefono") {
        //Se inicia la cuenta para enviar otra vez el codigo
        this.empezarContadorEnviarDeNuevo();
      } else {
        //Se inicia la cuenta para enviar otra vez el codigo
        this.empezarContadorEnviarDeNuevoCorreo();
      }
    },

    //Metodo que simula un contador de manera recursiva
    empezarContadorEnviarDeNuevo() {
      this.cargando_volver_enviar = true;

      //Se para el codigo durante 1 segundo(1000 ms) para que simule un contador real
      setTimeout(() => {
        //En el caso de que el contador sea mayor que 0 se va ir reduciendo el valor del mismo y se vuelve a llamar a la funcion
        if (this.contador > 0) {
          this.contador--;
          this.empezarContadorEnviarDeNuevo();
        } else {
          //En el caso de que el contadro sea igual a 0 se reiniciara el contador
          this.contador = 59;
          this.cargando_volver_enviar = false;
        }
      }, 1000);
    },

    //Metodo que simula un contador de manera recursiva
    empezarContadorEnviarDeNuevoCorreo() {
      this.cargando_volver_enviar_correo = true;

      //Se para el codigo durante 1 segundo(1000 ms) para que simule un contador real
      setTimeout(() => {
        //En el caso de que el contador sea mayor que 0 se va ir reduciendo el valor del mismo y se vuelve a llamar a la funcion
        if (this.contador_correo > 0) {
          this.contador_correo--;
          this.empezarContadorEnviarDeNuevoCorreo();
        } else {
          //En el caso de que el contadro sea igual a 0 se reiniciara el contador
          this.contador_correo = 59;
          this.cargando_volver_enviar_correo = false;
        }
      }, 1000);
    },
  },
};
</script>
<style lang=""></style>
