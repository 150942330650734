import apiClient from "./index.js";

const relative_path = "token";

export default {

  enviarMensajeConToken(params) {
    return apiClient.post(relative_path + "/enviar-mensaje-token",params);
  },
  validarCodigoSms(codigo,numero_telefono){
    return apiClient.post(relative_path + "/validar-codigo-sms",{codigo:codigo,numero_telefono:numero_telefono});
  }
}