<template>
      <v-card>
            <v-card-title class="text-h5 justify-center">
               {{ titulo}}
            </v-card-title>

            <v-card-text >
                {{ mensaje }} {{codigo}}
                <v-row class="mx-10 mt-5 mb-4">
                <v-col cols="12" sm="9">
                    <v-text-field label="Código" dense hide-details outlined></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-btn color="success">Enviar</v-btn>
                </v-col>
            </v-row>
            </v-card-text> 
               
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                dark
                @click="$emit('modal-mensaje-error', 'cancelar', emisor)"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="primary"
                dark
                @click="$emit('modal-mensaje-error', 'aceptar', emisor)">
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
</template>

<script>
export default {
    props: ['titulo', 'mensaje', 'emisor','codigo'],
    data: () => ({

    })
}
</script>

<style>

</style>