//Se importa la instancia de axios
import apiClient from "../../general/services/index.js";

//Ruta a la que se va acceder de la api
const relative_path = "/beneficiario/";

export default {
  /**
   * Funcion que trae todos los beneficiarios que le pertenecen a un titular y a un grupo
   * @param {number} id_titular id del titular en la tabla contratos_pre_titulares_afi (ID_Titular)
   * @param {number} id_grupo id del titular en la tabla contratos_pre_grupos_afi (ID_Grupo)
   * @returns {Array<Object>} beneficiarios pertenecientes al titular y grupo
   */
  beneficiariosTitular(id_titular, id_grupo,tabla) {
    return apiClient.get(
      relative_path + "beneficiarios-titular/" + id_titular + "/" + id_grupo+ "/" + tabla
    );
  },

  /**
   * Funcion que consulta si un numero de documento pertenenece a un beneficiario en la tabla beneficiarios de la db medisoft
   * @param {number} numero_documento numero de documento a consultar
   * @returns {Array<Object>} beneficiario al que le pertencence el documento
   */
  consultarBeneficiarioRmv(numero_documento) {
    return apiClient.get(
      relative_path + "consultar-beneficiario-rmv/" + numero_documento
    );
  },

  /**
   * Funcion que consulta el estado en cartera de los beneficiarios de un titular en especifico en la db medisoft
   * @param {number} id_titular id del titular a consultar los beneficiarios
   * @returns {Array<Object>} beneficiarios que pertenecen al titular
   */
  consultarEstadoCarteraBenRmv(id_titular) {
    return apiClient.get(
      relative_path + "consultar-estado-cartera-beneficiario-rmv/" + id_titular
    );
  },

  /**
   * Funcion que consulta el total de los servicios de un beneficiario en la db medisoft
   * @param {number} numero_documento  numero de documento del benenficiario
   */
  consultarTotalServicios(numero_documento) {
    return apiClient.get(relative_path + "total-servicios/" + numero_documento);
  },

  consultarMesesBen(numero_documento) {
    return apiClient.get(
      relative_path + "consultar-meses-beneficiario/" + numero_documento
    );
  },
};
